import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import AdminMenu from '../../components/Layouts/AdminMenu'
import { useAuth } from '../../context/auth'
import { Link } from 'react-router-dom'
import ConfirmationDialog from '../../components/Common/ConfirmationDialog'

const Owner = () => {

    const [auth, setAuth] = useAuth()
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [errorMessages, setErrorMessages] = useState({})
    const [owners, setOwners] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [timeFilter, setTimeFilter] = useState('all')
    const [activeStatus, setActiveStatus] = useState('')
    const [arrayKey, setArrayKey] = useState('')

    const controller = new AbortController()
    const signal = controller.signal

    const handleStatusClick = (key) => {

        setArrayKey(key)

        if (owners[key]?.Active) {
            const confirmDialogButton = document.getElementById('confirmDialogButton')
            confirmDialogButton.click()
        } else {
            handleStatus(key)
        }
    }

    const handleCancelDeactivate = () => {

        if (owners[arrayKey]?.Active) {
            document.querySelector(`#ownerActiveCheckbox${arrayKey}`).checked = true
        } else {
            document.querySelector(`#ownerActiveCheckbox${arrayKey}`).checked = false
        }
    }

    const handleStatus = async (key) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/owner-active`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify({ id: owners[key]?._id, active: owners[key]?.Active ? false : true }),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getOwners()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to change status: ${error}`)
        }
    }

    const handleDelete = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/owner-delete/${owners[arrayKey]?._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getOwners()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to delete owner: ${error}`)
        }
    }

    const handleCancelDelete = () => {
    }

    const getOwners = async () => {

        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/all-owners/?page=${currentPage}&limit=${pageSize}&active=${activeStatus}&search=${searchKeyword}&time=${timeFilter}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setOwners(apiResponse?.data?.Owners)
                    setTotalCount(apiResponse?.data?.TotalCount)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                setOwners([])
                setTotalCount(0)
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }

        } catch (error) {
            console.log(`Unable to fetch owners: ${error}`)
        }

    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        getOwners()
        return () => controller.abort()
    }, [currentPage, searchKeyword, timeFilter, activeStatus])

    return (
        <Layout>
            <AdminMenu userPermissions={auth?.user?.userPermission} />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <div className='row g-3 mb-4 align-items-center justify-content-between'>
                            <div className='col-auto'>
                                <h1 className='app-page-title mb-0'>Owners</h1>
                            </div>
                            <div className='col-auto'>
                                <div className='page-utilities'>
                                    <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                        <div className='col-auto'>
                                            <form className='table-search-form row gx-1 align-items-center'>
                                                <div className='col-auto'>
                                                    <input type='text' name='searchKeyword' className='form-control search-orders' placeholder='Search' value={searchKeyword} onChange={(e) => {
                                                        setSearchKeyword(e.target.value)
                                                        setCurrentPage(1)
                                                    }} />
                                                </div>
                                            </form>

                                        </div>
                                        <div className='col-auto'>

                                            <select value={timeFilter} className='form-select w-auto' onChange={(e) => {
                                                setTimeFilter(e.target.value)
                                            }} >
                                                <option value='all'>All</option>
                                                <option value='thisWeek'>This week</option>
                                                <option value='thisMonth'>This month</option>
                                                <option value='lastThreeMonths'>Last 3 months</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav id='owner-all-tab' className='owner-all-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4'>
                            <a className='flex-sm-fill text-sm-center nav-link active' data-bs-toggle='tab' role='tab' aria-selected='true' onClick={() => {
                                setActiveStatus('')
                                setCurrentPage(1)
                            }}>
                                All
                            </a>
                            <a className='flex-sm-fill text-sm-center nav-link' data-bs-toggle='tab' role='tab' aria-selected='false' onClick={() => {
                                setActiveStatus(1)
                                setCurrentPage(1)
                            }}>
                                Active
                            </a>
                            <a className='flex-sm-fill text-sm-center nav-link' data-bs-toggle='tab' role='tab' aria-selected='false' onClick={() => {
                                setActiveStatus(0)
                                setCurrentPage(1)
                            }}>
                                Inactive
                            </a>
                        </nav>

                        <div className='tab-content' id='owner-all-tab-content'>
                            <div className='tab-pane fade show active' id='owner-all' role='tabpanel' aria-labelledby='owner-all-tab'>
                                <div className='app-card app-card-orders-table shadow-sm mb-5'>
                                    <div className='app-card-body'>
                                        <div className='table-responsive'>
                                            <table className='table app-table-hover mb-0 text-left'>
                                                <thead>
                                                    <tr>
                                                        <th className='cell'>Name</th>
                                                        <th className='cell'>Email</th>
                                                        <th className='cell'>Mobile</th>
                                                        {/* <th className='cell'>Country</th> */}
                                                        <th className='cell'>City   </th>
                                                        <th className='cell'>Products</th>
                                                        <th className='cell'>Created On</th>
                                                        <th className='cell'>Updated On</th>
                                                        <th className='cell'>Active</th>
                                                        <th className='cell'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        owners?.map((owner, index) => (
                                                            <tr key={owner?._id}>
                                                                <td className='cell'>
                                                                    <span className='truncate'>{owner?.Name}</span>
                                                                </td>
                                                                <td className='cell'>
                                                                    <span className='truncate'>{owner?.Email}</span>
                                                                </td>
                                                                <td className='cell'>{owner?.Mobile}</td>
                                                                <td className='cell'>
                                                                    <span>{owner?.City}</span>
                                                                    <span className='note'>{owner?.State},&nbsp;{owner?.Country}</span>
                                                                </td>
                                                                <td className='cell'>
                                                                    <Link className='btn-sm btn-secondary text-decoration-none' to=''>{owner?.TotalQrs}</Link>
                                                                </td>
                                                                <td className='cell'>{owner?.CreatedAt}</td>
                                                                <td className='cell'>{owner?.UpdatedAt}</td>
                                                                <td className='cell'>
                                                                    <div className='form-check form-switch'>
                                                                        <input className='form-check-input' type='checkbox' role='switch' id={`ownerActiveCheckbox${index}`} defaultChecked={owner?.Active} onChange={() => {
                                                                            handleStatusClick(index)
                                                                        }} />
                                                                        <input type='hidden' data-bs-toggle='modal' data-bs-target='#confirmDialog' id='confirmDialogButton' />
                                                                    </div>
                                                                </td>
                                                                <td className='cell'>
                                                                    <Link className='text-decoration-none' to='' data-bs-toggle='modal' data-bs-target='#confirmDeleteDialog' onClick={
                                                                        () => { setArrayKey(index) }
                                                                    }>

                                                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-trash text-danger' viewBox='0 0 16 16'>
                                                                            <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z' />
                                                                            <path d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z' />
                                                                        </svg>

                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {
                                                        owners.length <= 0 && <tr key={'a'}>
                                                            <td className='cell' colSpan={9}>No data available</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <nav className='app-pagination'>
                                <ul className='pagination justify-content-end'>
                                    <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                                        <a className='page-link' href='#' tabIndex='-1' aria-disabled='true' onClick={() => handlePageChange(currentPage - 1)}>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left' viewBox='0 0 16 16'>
                                                <path fillRule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8' />
                                            </svg>&nbsp;Previous</a>
                                    </li>
                                    <li className={`page-item ${currentPage * pageSize >= totalCount ? 'disabled' : ''}`}>
                                        <a className='page-link' href='#' onClick={() => handlePageChange(currentPage + 1)}>Next&nbsp;
                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                                                <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8' />
                                            </svg></a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>

            <div className='modal fade' id='confirmDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>

                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to deactivate?`}
                        onConfirm={handleStatus}
                        onCancel={handleCancelDeactivate}
                        arrayKey={arrayKey}
                    />
                )}
            </div>

            <div className='modal fade' id='confirmDeleteDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to delete?`}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        arrayKey={arrayKey}
                    />
                )}
            </div>
        </Layout>
    )
}

export default Owner