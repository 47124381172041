import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import AdminMenu from '../../components/Layouts/AdminMenu'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const AdminDashboard = () => {

    const [auth, setAuth] = useAuth()
    const [dashboardDetails, setDashboardDetails] = useState()

    const controller = new AbortController()
    const signal = controller.signal

    const getAdminDashboardDetails = async () => {

        let errorMessages = {}

        try {

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/dashboard/details`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setDashboardDetails(apiResponse?.data)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    console.log(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }

        } catch (error) {
            console.log(`Unable to fetch dashboard details: ${error}`)
        }
    }

    useEffect(() => {
        getAdminDashboardDetails()
        return () => controller.abort()
    }, [])


    return (
        <Layout>
            <AdminMenu userPermissions={auth?.user?.userPermission} />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <h1 className='app-page-title'>Overview</h1>
                        <div className='row g-4 mb-4'>
                            <div className='col-6 col-lg-3'>
                                <div className='app-card app-card-stat shadow-sm h-100'>
                                    <div className='app-card-body p-3 p-lg-4'>
                                        <h4 className='stats-type mb-1'>QRs</h4>
                                        <div className='stats-figure'>{dashboardDetails?.qrs[0]?.TotalQrs || 0}</div>
                                        <div className='stats-meta text-success'>Total</div>

                                    </div>
                                    <Link className='app-card-link-mask' to={`/dashboard/admin/qrs`}></Link>
                                </div>
                            </div>

                            <div className='col-6 col-lg-3'>
                                <div className='app-card app-card-stat shadow-sm h-100'>
                                    <div className='app-card-body p-3 p-lg-4'>
                                        <h4 className='stats-type mb-1'>Owners</h4>
                                        <div className='stats-figure'>{dashboardDetails?.owners[0]?.TotalOwners || 0}</div>
                                        <div className='stats-meta text-success'>Total</div>

                                    </div>
                                    <Link className='app-card-link-mask' to={`/dashboard/admin/owners`}></Link>
                                </div>
                            </div>
                            <div className='col-6 col-lg-3'>
                                <div className='app-card app-card-stat shadow-sm h-100'>
                                    <div className='app-card-body p-3 p-lg-4'>
                                        <h4 className='stats-type mb-1'>Activated QRs</h4>
                                        <div className='stats-figure'>{dashboardDetails?.activatedQrs[0]?.TotalActivatedQrs || 0}</div>
                                        <div className='stats-meta text-success'>Total</div>
                                    </div>
                                    <Link className='app-card-link-mask' to={`/dashboard/admin/tagged-qrs`}></Link>
                                </div>
                            </div>
                            <div className='col-6 col-lg-3'>
                                <div className='app-card app-card-stat shadow-sm h-100'>
                                    <div className='app-card-body p-3 p-lg-4'>
                                        <h4 className='stats-type mb-1'>Categories</h4>
                                        <div className='stats-figure'>{dashboardDetails?.categories[0]?.TotalCategories || 0}</div>
                                        <div className='stats-meta text-success'>Total</div>

                                    </div>
                                    <Link className='app-card-link-mask' to={`/dashboard/admin/categories`}></Link>
                                </div>
                            </div>
                            <div className='col-6 col-lg-3'>
                                <div className='app-card app-card-stat shadow-sm h-100'>
                                    <div className='app-card-body p-3 p-lg-4'>
                                        <h4 className='stats-type mb-1'>Sub-Admins</h4>
                                        <div className='stats-figure'>{dashboardDetails?.subAdmins[0]?.TotalOwners || 0}</div>
                                        <div className='stats-meta text-success'>Total</div>

                                    </div>
                                    <Link className='app-card-link-mask' to={`/dashboard/admin/subAdmins`}></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AdminDashboard