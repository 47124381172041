import React, { useState } from 'react'
import QrReader from 'react-qr-scanner'

const TagQr = () => {
  const [result, setResult] = useState('')

  const handleScan = (data) => {
    if (data) {
      setResult(data.text)
    }
  }

  const handleError = (error) => {
    console.error('QR code scan error:', error)
  }

  return (
    <div className='app-content pt-3 p-md-3 p-lg-4'>
      <div className='container-xl'>
        <h1 className='app-page-title'>Tag</h1>
        <div className='row no-gutters justify-content-center'>
          <QrReader delay={500} onError={handleError} facingmode={'environment'} onScan={handleScan} />
        </div>
      </div>
    </div>
  );
};

export default TagQr
