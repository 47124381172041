import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {

    const goBack = () => {
        window.history.back()
    }

    return (
        <>
            <div className='app-404-page'>
                <div className='container mb-5'>
                    <div className='row'>
                        <div className='col-12 col-md-11 col-lg-7 col-xl-6 mx-auto'>
                            <div className='app-branding text-center mb-5'>
                                <Link className='app-logo' to='/'>
                                    <img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' /></Link>
                            </div>
                            <div className='app-card p-5 text-center shadow-sm'>
                                <h1 className='page-title mb-4'>404<br /><span className='font-weight-light'>Page Not Found</span></h1>
                                <div className='mb-4'>
                                    Sorry, we can't find the page you're looking for.
                                </div>
                                <button className='btn app-btn-primary' onClick={goBack}>Go to back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='mt-auto w-100 bottom-0 start-0 py-2' style={{ background: '#edfdf6' }}>

                <div className='col-sm-12 text-center'>
                    <p className='mb-0 text-sm text-gray-600'>&copy;{new Date().getFullYear()} ScanConnect&trade;</p>
                </div>
            </footer>
        </>
    )
}

export default NotFound