
import React from 'react';

const ConfirmationDialog = ({ message, onConfirm, onCancel, arrayKey }) => {
    return (

        <div className='modal-dialog modal-sm modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h5 className='modal-title'>Confirm</h5>
                </div>
                <div className='modal-body'>
                    <div className='confirmation-dialog'>
                        <p>{message}</p>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button type='submit' onClick={() => { onConfirm(arrayKey) }} className='btn btn-success me-auto' data-bs-dismiss='modal'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-check-lg' viewBox='0 0 16 16'>
                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z' />
                        </svg>&nbsp;Yes
                    </button>
                    <button onClick={onCancel} type='button' className='btn btn-danger' data-bs-dismiss='modal'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x' viewBox='0 0 16 16'>
                            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' />
                        </svg>&nbsp;No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;
