import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth'
import { Outlet, useNavigate } from 'react-router-dom'
import Spinner from '../Spinner'

const Private = () => {
    const [ok, setOk] = useState(false)
    const [auth, setAuth] = useAuth()
    const navigate = useNavigate()

    const authCheck = async () => {

        try {
            const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/user-auth`, {
                method: 'GET',
                headers: {
                    'Authorization': auth?.token
                }
            })

            if (apiResponse.ok) {
                setOk(true)
            }
            else {
                setOk(false)

                if (apiResponse.status == 401 || apiResponse.status == 498) {
                    setAuth({
                        ...auth,
                        user: null,
                        token: ''
                    })

                    localStorage.removeItem('auth')

                    navigate(`/login`)
                }
            }
        } catch (error) {
            navigate(`/`)
        }
    }

    useEffect(() => {


        if (auth?.token) {
            authCheck()
        }

    }, [auth?.token])

    return ok ? <Outlet /> : <Spinner />
}

export default Private