import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

const AdminMenu = ({ userPermissions }) => {

    // Define menu items and their permissions
    const menuItems = [
        {
            name: 'Generate QR',
            permission: 'qrs',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-qr-code' viewBox='0 0 16 16'>
                <path d='M2 2h2v2H2z' />
                <path d='M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z' />
                <path d='M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z' />
                <path d='M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z' />
                <path d='M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z' />
            </svg>
        },
        {
            name: 'Activated QRs',
            permission: 'tagged-qrs',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-qr-code-scan' viewBox='0 0 16 16'>
                <path d='M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z' />
                <path d='M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z' />
                <path d='M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z' />
                <path d='M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z' />
                <path d='M12 9h2V8h-2z' />
            </svg>
        },
        {
            name: 'Owners',
            permission: 'owners',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-people' viewBox='0 0 16 16'>
                <path d='M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4' />
            </svg>
        },
        {
            name: 'Settings',
            permission: 'settings',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-gear' viewBox='0 0 16 16'>
                <path d='M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0' />
                <path d='M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z' />
            </svg>,
            arrowIcon: <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-down' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
            </svg>,
            submenuItems: [
                {
                    name: 'Sub Admin',
                    permission: 'subAdmins',
                    icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-person-gear' viewBox='0 0 16 16'>
                        <path d='M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0' />
                    </svg>
                },
                {
                    name: 'Categories',
                    permission: 'categories',
                    icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-diagram-3' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z' />
                    </svg>
                }
            ]
        }
    ]

    const sidePanelToggler = () => {
        const sidePanel = document.getElementById('app-sidepanel')

        if (sidePanel.classList.contains('sidepanel-visible')) {
            sidePanel.classList.remove('sidepanel-visible')
            sidePanel.classList.add('sidepanel-hidden')
        } else {
            sidePanel.classList.remove('sidepanel-hidden')
            sidePanel.classList.add('sidepanel-visible')
        }
    }

    useEffect(() => {
        // Function to handle responsive side panel on window load and resize
        const handleResize = () => {
            const w = window.innerWidth
            const sidePanel = document.getElementById('app-sidepanel')

            if (w >= 1200) {
                sidePanel.classList.remove('sidepanel-hidden')
                sidePanel.classList.add('sidepanel-visible')
            } else {
                sidePanel.classList.remove('sidepanel-visible')
                sidePanel.classList.add('sidepanel-hidden')
            }
        }

        // Add event listeners for window load and resize
        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)

        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener('load', handleResize)
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const sidePanelClose = (e) => {
        e.preventDefault()
        document.getElementById('sidepanel-toggler').click()
    }

    const sidePanelDrop = (e) => {
        e.preventDefault()
        document.getElementById('sidepanel-toggler').click()
    }

    return (
        <>
            {/* Sidebar implementation */}

            <div className='col-auto'>
                <Link id='sidepanel-toggler' onClick={sidePanelToggler} className='sidepanel-toggler d-inline-block d-xl-none' to='#'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' role='img'><title>Menu</title><path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'></path></svg>
                </Link>
            </div>

            <div id='app-sidepanel' className='app-sidepanel z-3'>
                <div id='sidepanel-drop' onClick={sidePanelDrop} className='sidepanel-drop'></div>
                <div className='sidepanel-inner d-flex flex-column'>
                    <NavLink to='#' id='sidepanel-close' onClick={sidePanelClose} className='sidepanel-close d-xl-none text-decoration-none'>&times</NavLink>

                    <nav id='app-nav-main' className='app-nav app-nav-main flex-grow-1'>
                        <ul className='app-menu list-unstyled accordion' id='menu-accordion'>
                            {menuItems.map(item => (
                                // Check if the user has permission for this menu item
                                userPermissions.includes(item.permission) && (
                                    <MenuItem key={item.name} item={item} userPermissions={userPermissions} />
                                )
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}

const MenuItem = ({ item, userPermissions }) => {
    const { name, icon, permission, submenuItems } = item

    // Check if the user has permission for this menu item
    const hasPermission = userPermissions.includes(permission)

    if (!hasPermission) {
        return null // Don't render the menu item if the user doesn't have permission
    }

    return (

        <li className='nav-item'>
            {submenuItems ? (
                // Render submenu with conditional rendering
                <ul className='submenu-list list-unstyled'>
                    <SubMenu item={item} userPermissions={userPermissions} />
                </ul>
            ) : (
                // Render single menu item
                <NavLink className='nav-link' to={`/dashboard/admin/${permission}`}>
                    <span className='nav-icon'>{icon}</span>
                    <span className='nav-link-text'>{name}</span>
                </NavLink>
            )}
        </li>
    )
}

const SubMenu = ({ item, userPermissions }) => {
    const { name, icon, arrowIcon, permission, submenuItems } = item

    // Check if the user has permission for any submenu item
    const hasSubMenuPermission = submenuItems.some(submenuItem => userPermissions.includes(submenuItem.permission))

    if (!hasSubMenuPermission) {
        return null // Don't render the submenu if the user doesn't have permission for any submenu item
    }

    return (
        <li className='nav-item has-submenu'>
            {/* Submenu toggle */}
            <Link className='nav-link submenu-toggle' to={''} data-bs-toggle='collapse' data-bs-target={`#${permission}SubMenu`} aria-expanded='false'>
                <span className='nav-icon'>{icon}</span>
                <span className='nav-link-text'>{name}</span>
                <span className='submenu-arrow'>{arrowIcon}</span>
            </Link>
            {/* Submenu items */}

            <div id={`${permission}SubMenu`} className='collapse submenu' data-bs-parent='#menu-accordion'>
                <ul className='submenu-list list-unstyled'>
                    {submenuItems.map(submenuItem => (
                        // Check if the user has permission for this submenu item
                        userPermissions.includes(submenuItem.permission) && (
                            <MenuItem key={submenuItem.name} item={submenuItem} userPermissions={userPermissions} />
                        )
                    ))}
                </ul>
            </div>
        </li>
    )
}

export default AdminMenu
