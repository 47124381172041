import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import TagQr from './pages/Owner/TagQr'
import NotFound from './pages/NotFound'
import Register from './pages/Auth/Register'
import Login from './pages/Auth/Login'
import Dashboard from './pages/Owner/Dashboard'
import Private from './components/Routes/Private'
import Admin from './components/Routes/Admin'
import AdminDashboard from './pages/Admin/AdminDashboard'
import Qr from './pages/Admin/Qr'
import Scan from './pages/User/Scan'
import Connect from './pages/User/Connect'
import Owner from './pages/Admin/Owner'
import Category from './pages/Admin/Category'
import TaggedQr from './pages/Admin/TaggedQr'
import OwnerTaggedQr from './pages/Owner/OwnerTaggedQr'
import MobileSignUp from './pages/MobileSignUp'
import Otp from './pages/Auth/Otp'
import Chat from './pages/User/Chat'
import ForgotPassword from './pages/Auth/ForgotPassword'
import SubAdmin from './pages/Admin/SubAdmin'
import ResetPassword from './pages/Auth/ResetPassword'
import CallLogs from './pages/Owner/CallLogs'
import RegistrationSuccess from './pages/Auth/RegistrationSuccess'
// import UserBocked from './pages/User/UserBlocked'
import Message from './pages/User/Message'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/mobile-sign-up' element={<MobileSignUp />} />
        <Route path='/register' element={<Register />} />
        <Route path='/registration-success' element={<RegistrationSuccess />} />
        <Route path='/login' element={<Login />} />
        <Route path='/otp-auth' element={<Otp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/connect' element={<Connect />} />
        <Route path='/chat' element={<Chat />} />
        {/* <Route path='/blocked-user' element={<UserBocked />} /> */}
        <Route path='/message' element={<Message />} />

        <Route path='/:scan' element={<Scan />} />
        <Route path='*' element={<NotFound />} />

        <Route path='/Dashboard' element={<Private />} >
          <Route path='tag' element={<TagQr />} />
          <Route path='user' element={<Dashboard />} />
          <Route path='owner/tagged-qrs' element={<OwnerTaggedQr />} />
          <Route path='owner/call-logs' element={<CallLogs />} />
        </Route>
        <Route path='/Dashboard' element={<Admin />} >
          <Route path='admin' element={<AdminDashboard />} />
          <Route path='admin/qrs' element={<Qr />} />
          <Route path='admin/tagged-qrs' element={<TaggedQr />} />
          <Route path='admin/owners' element={<Owner />} />
          <Route path='admin/categories' element={<Category />} />
          <Route path='admin/subAdmins' element={<SubAdmin />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
