import React from 'react'
import Layout from '../components/Layouts/Layout'
import { useAuth } from '../context/auth'

const Home = () => {
    const [auth, setAuth] = useAuth()

    const animationContainer = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
    };

    return (
        <Layout>
            <div className='app-content pt-3 p-md-3 p-lg-4'>
                <div className='container-xl'>

                    <div className='row'>
                        <div className='col-12 col-md-11 col-lg-7 col-xl-6 mx-auto'>
                            <div className='app-branding text-center mb-5'>
                                <img className='img-fluid me-2' height={100} width={100} src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' />
                            </div>
                            <div className='p-5 text-center'>
                                <h1 className='page-title mb-4'>Launch soon...<br />
                                </h1>
                                <p className='subline'> We are preparing
                                    something exciting
                                    & amazing for you..</p>
                                <div className='mb-4'>
                                    <p>Nice to meet you.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default Home