import React from 'react'
import Header from './Header'
import Footer from './Footer'
import InternetSpeedChecker from '../Common/InternetSpeedChecker'

const Layout = (props) => {
    return (
        <>
            <div className='app'>
                <Header />
                <div className='app-wrapper d-flex flex-column vh-100'>
                    <InternetSpeedChecker>
                        {props.children}
                    </InternetSpeedChecker>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Layout