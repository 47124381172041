import React, { useEffect, useState, useRef } from 'react'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt'
import { ZIM } from 'zego-zim-web'
import { useLocation, useNavigate } from 'react-router-dom'
import InternetSpeedChecker from '../../components/Common/InternetSpeedChecker'
import Loader from '../../components/Loader'

const Connect = () => {

  const location = useLocation()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState({})
  const [optForm, setOptForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    otp: ''
  })

  const [userInfo, setUserInfo] = useState({
    userName: '',
    userId: '',
  })
  // const [callResponseMessage, setCallResponseMessage] = useState('')

  const controller = new AbortController()
  const signal = controller.signal
  const connectData = location?.state || {}
  const connectMedium = connectData?.ConnectMedium?.split('|')
  const zeroCloudInstance = useRef(null)

  const uniqueId = Math.random().toString(36).substr(2, 9)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleChat = async (e) => {
    e.preventDefault()
    navigate('/chat', { state: { connectData, userInfo } })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrorMessages(validateValues(formData));
    setSubmitting(true);
  }

  const finishSubmit = async () => {
    try {
      let errorMessages = {}

      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/register-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        signal
      })

      if (response.ok) {
        const apiResponse = await response.json()
        if (apiResponse.success) {
          setOptForm(true)
        }
        else {
          errorMessages.response = apiResponse.message
          setErrorMessages(errorMessages)
        }
      }
      else {
        throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
      }
    }
    catch (error) {
      console.log(`Unable to create account: ${error}`)
    }
    finally {
      // setSubmitting(false)
      // setShowModal(false)
    }
  }

  const validateValues = (data) => {
    let forNameRegEx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/
    // let forMobileRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    let forMobileRegEx = /^\+(?:[0-9] ?){6,14}[0-9]$/

    let errorMessages = {}

    if (data.name == '') {
      errorMessages.name = 'Name required'
    }
    else if (!data.name.match(forNameRegEx)) {
      errorMessages.name = 'Enter valid name'
    }

    if (data.mobile == '') {
      errorMessages.mobile = 'Mobile required'
    }
    else if (forMobileRegEx.test(data.mobile) == false) {
      errorMessages.mobile = 'Enter valid mobile or missing country code'
    }

    return errorMessages
  }

  const isUserBlock = async () => {
    try {

      let scVisitorId = JSON.parse(localStorage.getItem('scVisitorId'))

      if (scVisitorId) {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/get-user/${scVisitorId?.userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })

        if (response.ok) {
          const apiResponse = await response.json()
          if (apiResponse?.success) {

            if (apiResponse?.data?.IsBlock) {
              navigate('/message', { state: { title: 'You are blocked', text: 'Please contact support for further assistance' } })

            }
          }
          else {
            throw new Error(`Response error, message:${response.message}`)
          }
        }
        else {
          throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
        }
      }



    } catch (error) {
      console.log(`Unable to fetch user: ${error}`)
    }
  }

  async function init() {
    setLoading(true)

    //Production
    const appID = 1891943092
    const serverSecret = '415d8feeb5e50d05842ceca7a1bef648'

    const KitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      null,
      userInfo.userId,
      userInfo.userName
    )
    zeroCloudInstance.current = ZegoUIKitPrebuilt.create(KitToken)
    // add plugin
    zeroCloudInstance.current.addPlugins({ ZIM })
    setLoading(false)
  }


  const generateVisitorId = async () => {
    return {
      userId: formData.mobile == '' ? uniqueId : formData.mobile,
      userName: formData.mobile == '' ? 'visitor_' + uniqueId : formData.name.split(' ')[0]
    }
  }


  const getVisitorId = async () => {

    let scVisitorId = JSON.parse(localStorage.getItem('scVisitorId'))

    if (!scVisitorId) {
      scVisitorId = await generateVisitorId()
      localStorage.setItem('scVisitorId', JSON.stringify(scVisitorId))
    }

    return setUserInfo(scVisitorId)
  }

  function createCallLog(reason, callType, ownerId, userId, userName, taggedId) {
    try {

      fetch(`${process.env.REACT_APP_API_URL}/v1/owner/create-call-log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'reason': reason, 'callType': callType, 'ownerId': ownerId, 'userId': userId, 'userName': userName, 'taggedId': taggedId })
      })
    }
    catch (error) {
      console.log(`Unable to create call log: ${error}`)
    }
  }

  function nextCall(callCounter, callType, connectData) {
    setTimeout(() => {
      handleSend(callType, connectData?.Owners[callCounter]?._id, connectData?.Owners[callCounter]?.Name)
    }, 2000)
  }

  function handleSend(callType, calleeId, name) {

    var callCounter = parseInt(localStorage.getItem('callCounter'))

    if (!calleeId) {
      alert('userID cannot be empty!!')
      return
    }

    // send call invitation
    zeroCloudInstance.current
      .sendCallInvitation({
        callees: [{ userID: calleeId, userName: name }],
        callType: callType,
        timeout: 50,
        notificationConfig: {
          resourcesID: 'zego_scanconnect1final',
          title: connectData?.Number !== '' ? connectData?.Number : connectData?.LostItemName,
          message: 'ScanConnect incoming call...'
        }
      })
      .then((res) => {

        if (res.errorInvitees.length) {
          alert('Dose not exist or is offline.')
        }
      })
      .catch((err) => {
        console.error(err)
        //6000104 - network error
        //6000121 - zim not login
        //6000281 - user not login
        const errorData = JSON.parse(err)

        if (errorData.code == 6000281) {//user not login
          callCounter = callCounter + 1
          localStorage.setItem('callCounter', parseInt(callCounter))
          nextCall(callCounter, callType, connectData)
        }
        else {
          // setCallResponseMessage(`${errorData.message}, scan QR again.`)
          navigate('/message', { state: { title: 'Oops!', text: `${errorData.message} Scan QR again.` } })
        }
      })

    zeroCloudInstance.current.setCallInvitationConfig({
      enableNotifyWhenAppRunningInBackgroundOrQuit: true,
      onSetRoomConfigBeforeJoining: (callType) => {
        return {
          showScreenSharingButton: false,
          showMyCameraToggleButton: false,
          showAudioVideoSettingsButton: false,
          showTextChat: false,
          showUserList: false,
          showRoomDetailsButton: false,
          showLeavingView: false,
          showLeaveRoomConfirmDialog: false,
          lowerLeftNotification: {
            showUserJoinAndLeave: false,
            showTextChat: false,
          },
          branding: {
            logoURL: `${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogo.svg`
          },
          layout: 'Auto',// | 'Grid' | 'Sidebar';
        }
      },
      onCallInvitationEnded: (reason, data) => {

        if (reason == 'Declined' || reason == 'Busy') {
          localStorage.removeItem('callCounter')
          // setCallResponseMessage('Receiver is busy now, thank you for contact.')
          navigate('/message', { state: { title: 'Busy!', text: 'Receiver is busy now, thank you for contact.' } })
        }
        else if (reason == 'Canceled') {
          localStorage.removeItem('callCounter')
          // setCallResponseMessage('Thank you for contact.')
          navigate('/message', { state: { title: 'Thank You!', text: 'Thank you for contact.' } })

        }
        else if (reason == 'Canceled') {
          localStorage.removeItem('callCounter')
          // setCallResponseMessage('Thank you for contact.')
          navigate('/message', { state: { title: 'Thank You!', text: 'Thank you for contact.' } })

        }
        else if (reason == 'Timeout') {

          callCounter = callCounter + 1

          if (connectData?.TotalOwners > callCounter) {
            localStorage.setItem('callCounter', parseInt(callCounter))
            nextCall(callCounter, callType, connectData)
          }
          else {
            localStorage.removeItem('callCounter')
            // setCallResponseMessage('Receiver is not pickup your call, try again later.')
            navigate('/message', { state: { title: 'Thank You!', text: 'Receiver is not pickup your call, try again later.' } })

          }
        }
        else if (reason == 'LeaveRoom') {
          reason = 'success'
        }

        createCallLog(reason, callType, connectData?.Owners[callCounter]?._id, userInfo.userId, userInfo.userName, connectData?._id)
      },
    })
  }

  const handleResendOTP = async () => {
    try {
      let errorMessages = {}

      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/send-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: formData.mobile, rememberMe: false })
      })

      if (response.ok) {
        const apiResponse = await response.json()
        if (apiResponse?.success) {
          console.log('Resend OTP')
        }
        else {
          errorMessages.response = apiResponse.message
          setErrorMessages(errorMessages)
        }
      }
      else {
        throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
      }
    }
    catch (error) {
      console.log(`Unable to login: ${error}`)
    }
  }

  const handleVerifyOTP = async (e) => {
    e.preventDefault()

    try {

      let errorMessages = {}
      setErrorMessages('')

      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: formData.mobile, otp: formData.otp, rememberMe: false, verifyBy: 'user' })
      })

      if (response.ok) {
        const apiResponse = await response.json()
        if (apiResponse?.success) {

          // setUserInfo({
          //   userId: formData.mobile == '' ? uniqueId : formData.mobile,
          //   userName: formData.mobile == '' ? 'visitor_' + uniqueId : formData.name.split(' ')[0]
          // })
          let endUser = await generateVisitorId()
          setUserInfo(endUser)

          localStorage.setItem('scVisitorId', JSON.stringify(endUser))

          setShowModal(false)
          setSubmitting(false)
          // init()
        }
        else {
          errorMessages.response = apiResponse.message
          setErrorMessages(errorMessages)
        }
      }
      else {
        throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
      }
    }
    catch (error) {
      console.log(`Unable to login: ${error}`)
    }
  }

  useEffect(() => {

    isUserBlock()

    if (showModal == false)
      init()

    return () => controller.abort()

  }, [errorMessages, showModal])

  useEffect(() => {

    if (Object.keys(errorMessages).length == 0 && submitting) {
      finishSubmit()
    }
    else {
      setSubmitting(false)
    }

  }, [submitting])


  return (
    <InternetSpeedChecker>
      <div className='container'>
        <div className='row align-items-center vh-100'>
          <div className='rounded'>
            <div className='app-auth-branding mb-2 app-logo text-decoration-none text-center '>
              <img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' />
            </div>
            <div className='card border-secondary text-center w-95'>
              <div className='card-header'>
                <img className='logo-icon me-2 img-responsive' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogo.svg`} alt='ScanConnect Logo' height={40} />
              </div>
              {
                loading ? <Loader />
                  :
                  <div className='card-body'>
                    <h5 className='card-title'>{connectData?.Number !== '' ? connectData?.Number : connectData?.LostItemName}</h5>
                    <p className='card-text'>
                      {connectData?.Description}
                    </p>

                    <div className='col-lg-12 col-md-12 col-xs-12'>
                      {
                        // callResponseMessage == 'Person is not pickup your call, try again.' || callResponseMessage == '' ? (
                        <>
                          {
                            connectMedium.map((connect, index) => {
                              if (connect == 1) {
                                return <button key={index} className='btn app-btn-primary btn-md m-2' onClick={() => {
                                  localStorage.removeItem('callCounter')
                                  localStorage.setItem('callCounter', 0)
                                  handleSend(ZegoUIKitPrebuilt.InvitationTypeVideoCall, connectData?.Owners[0]?._id, connectData?.Owners[0]?.Name)
                                }} >Video Call</button>
                              }
                              if (connect == 2) {
                                return <button key={index} className='btn app-btn-primary btn-md m-2' onClick={() => {
                                  localStorage.removeItem('callCounter')
                                  localStorage.setItem('callCounter', 0)
                                  handleSend(ZegoUIKitPrebuilt.InvitationTypeVoiceCall, connectData?.Owners[0]?._id, connectData?.Owners[0]?.Name)
                                }} >Audio Call</button>
                              }
                              if (connect == 3) {
                                return <button key={index} className='btn app-btn-primary btn-md m-2' onClick={handleChat} >Chat</button>
                              }
                            })
                          }

                          {/* <p className='fw-normal'>{callResponseMessage}</p> */}

                        </>

                        // ) : (
                        //   <p className='fw-normal'>{callResponseMessage}</p>
                        // )

                      }

                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div >

      <div className={`modal fade ${showModal && 'show'}`} id='loginModal' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true' style={{ display: showModal ? 'block' : 'none' }}>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='row justify-content-center align-items-center' style={{ height: '80vh' }}>
                <div className='col-md-6'>
                  <div className='card text-center'>
                    <div className='card-body'>
                      {optForm ? (
                        <form className='row g-3 text-start'>
                          <div className='col-auto'>
                            <input type='text' readOnly className='form-control-plaintext' value={formData.mobile} />
                          </div>
                          <div className='col-auto'>
                            <input type='text' name='otp' className='form-control' maxLength={6} value={formData.otp} onChange={handleInputChange} placeholder='Enter OTP' />
                            {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                          </div>
                          <div className='col-auto'>
                            <button type='submit' className='btn app-btn-primary' onClick={handleVerifyOTP}>Verify</button>
                            <span className='m-3'>OR</span>
                            <button onClick={handleResendOTP} className='text-decoration-none btn app-btn-primary'>Resend OTP</button>
                          </div>
                         
                          
                        </form>
                      ) : (
                        <form className='login-form text-start'>
                          <div className='mb-3 row'>
                            <label htmlFor='name' className='col-sm-2 col-form-label'>Full Name</label>
                            <div className='col-sm-10'>
                              <input type='text' name='name' value={formData.name} id='name' className='form-control' placeholder='Full name' onChange={handleInputChange} />
                              {errorMessages.name ? (<p className='form-text text-danger m-0'> {errorMessages.name}</p>) : null}
                            </div>
                          </div>
                          <div className='mb-3 row'>
                            <label htmlFor='mobile' className='col-sm-2 col-form-label'>Mobile</label>
                            <div className='col-sm-10'>
                              <input type='text' name='mobile' id='mobile' maxLength={13} value={formData.mobile} className='form-control' placeholder='e.g +911234567890' onChange={handleInputChange} />
                              {errorMessages.mobile ? (<p className='form-text text-danger m-0'> {errorMessages.mobile}</p>) : <div className='form-text'>Please enter your mobile number with country code. We'll never share your mobile number with anyone else.</div>}
                            </div>
                          </div>
                          <div className='text-end'>
                            <button type='submit' className='btn app-btn-primary' onClick={handleSubmit}>Submit</button>
                          </div>
                          {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn app-btn-secondary' onClick={() => {
                getVisitorId()
                setShowModal(false)
              }} data-bs-dismiss='modal'>Skip</button>
            </div>
          </div>
        </div>
      </div>
    </InternetSpeedChecker >
  )
}

export default Connect