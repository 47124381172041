import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const Otp = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const authData = location?.state || {}

    const [auth, setAuth] = useAuth()
    const [maskedMobile, setMaskedMobile] = useState('')
    const [maskedEmail, setMaskedEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [errorMessages, setErrorMessages] = useState({})
    const [timer, setTimer] = useState(120)

    function setState() {
        const maskedString = '*'.repeat(authData?.mobile?.length - 4) + authData?.mobile?.slice(-4)
        const [username, domain] = authData?.email?.split('@')
        const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1)
        const maskedEmail = maskedUsername + '@' + domain

        setMaskedMobile(maskedString)
        setMaskedEmail(maskedEmail)
    }

    const sendWelcomMsg = async (mobile) => {
        try {

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/sms/send-welcome`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: mobile, props: { mob: mobile } })
            })

            if (response.ok) {

            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }

        }
        catch (error) {
            console.log(`Unable to login: ${error}`)
        }
    }

    const handleVerifyOTP = async () => {
        try {
            let errorMessages = {}
            setErrorMessages('')

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: authData.mobile, otp: otp, rememberMe: authData.rememberMe, verifyBy: 'owner' })
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {

                    if (authData?.register) {
                        sendWelcomMsg(apiResponse?.data?.mobile)
                        navigate('/registration-success', { state: apiResponse?.data?.name })
                    }
                    else {
                        setAuth({
                            ...auth,
                            user: apiResponse?.data,
                            token: apiResponse?.data?.token
                        })

                        localStorage.setItem('auth', JSON.stringify(apiResponse?.data))

                        navigate(`/dashboard/${apiResponse?.data?.role == 1 ? 'admin' : 'user'}`)
                    }

                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to login: ${error}`)
        }
    }

    const handleResendOTP = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: authData.mobile, rememberMe: authData.rememberMe })
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {
                    setTimer(120)
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to login: ${error}`)
        }
    }


    useEffect(() => {
        if (Object.keys(authData).length <= 0) {
            return navigate('/')
        }
        setState()
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevValue) =>
                    --prevValue
                )
            }, 1000)
            return () => { clearInterval(interval) }
        }

    }, [authData])


    return (
        <>  <div className='app-404-page'>
            <div className='container mb-5'>
                <div className='row'>
                    <div className='col-12 col-md-11 col-lg-7 col-xl-5 mx-auto'>
                        <div className='app-branding text-center mb-5'>
                            <img className='logo-icon me-2 app-logo' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' />
                        </div>
                        <div className='app-card p-5 text-center shadow-sm'>
                            <div className='position-relative'>
                                {timer > 0 ?

                                    <div className='p-2 text-center'>
                                        <h6>Please enter the one time password <br /> to verify your account</h6>
                                        <div>
                                            <span>A code has been sent to</span>
                                            <small>&nbsp;{maskedMobile}</small>&nbsp;&amp;
                                            <small>&nbsp;{maskedEmail}</small>
                                        </div>
                                        <div id='otp' className='inputs d-flex flex-row justify-content-center mt-2 card'>

                                            <input type='text' name='mobile' maxLength={6} value={otp} className='m-2 text-center form-control rounded' onChange={(e) => {
                                                setOtp(e.target.value)
                                            }} />

                                        </div>
                                        <div className='mt-4'>
                                            <button className={`btn btn-primary px-4 validate ${otp.length == 6 ? '' : `disabled`}`} onClick={handleVerifyOTP}>Verify</button>
                                        </div>
                                    </div>

                                    : ''}

                                <div className='content d-flex justify-content-center align-items-center'>
                                    {timer > 0 ? `OTP expire in ${timer} seconds` : <button onClick={handleResendOTP} className='text-decoration-none ms-3 btn btn-primary'>Resend OTP</button>}
                                </div>
                                {errorMessages.response ? (<p className='text text-danger mb-0 position-relative'> {errorMessages.response}</p>) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <footer className='mt-auto w-100 bottom-0 start-0 py-2' style={{ background: '#edfdf6' }}>
                <div className='col-sm-12 text-center'>
                    <p className='mb-0 text-sm text-gray-600'>&copy;{new Date().getFullYear()} ScanConnect&trade;</p>
                </div>
            </footer>
        </>
    )
}

export default Otp