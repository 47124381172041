import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import AdminMenu from '../../components/Layouts/AdminMenu'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import CategoryForm from '../../components/Froms/CategoryForm'
import ConfirmationDialog from '../../components/Common/ConfirmationDialog'


const Category = () => {

    const [auth, setAuth] = useAuth()
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [categories, setCategories] = useState([])
    const [errorMessages, setErrorMessages] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [action, setAction] = useState('')
    const [selectedValue, setSelectedValue] = useState(null)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [arrayKey, setArrayKey] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        errorLabel: ''
    })
    const controller = new AbortController()
    const signal = controller.signal

    const clearFormData = () => {
        setFormData({
            name: '',
            errorLabel: ''
        })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData))
        setSubmitting(true)
    }

    const handleStatusClick = (key) => {

        setArrayKey(key)

        if (categories[key]?.Active) {
            const confirmDialogButton = document.getElementById('confirmDialogButton')
            confirmDialogButton.click()
        } else {
            handleStatus(key)
        }
    }

    const handleCancelDeactivate = () => {

        if (categories[arrayKey]?.Active) {
            document.querySelector(`#categoryActiveCheckbox${arrayKey}`).checked = true
        } else {
            document.querySelector(`#categoryActiveCheckbox${arrayKey}`).checked = false
        }
    }

    const handleStatus = async (key) => {
        try {
            let errorMessages = {}
            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/category/category-active`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify({ id: categories[key]?._id, active: categories[key]?.Active ? false : true }),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getCategories()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to change status: ${error}`)
        }

    }

    const getCategories = async () => {

        try {

            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/category/all-categories/?page=${currentPage}&limit=${pageSize}&search=${searchKeyword}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setCategories(apiResponse?.data?.Categories)
                    setTotalCount(apiResponse?.data?.TotalCount)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                setCategories([])
                setTotalCount(0)
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }

        }
        catch (error) {
            console.log(`Unable to retrive categories: ${error}`)
        }
    }

    const saveCategory = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/category/create-category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify(formData)
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    const myModal = document.querySelector('#closeModal')
                    myModal.click()
                    getCategories()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to save category: ${error}`)
        }
        finally {
            setSubmitting(false)
        }

    }

    const updateCategory = async () => {
        try {

            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/category/update-category/${selectedValue?._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify(formData)
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    const myModal = document.querySelector('#closeModal')
                    myModal.click()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to update category: ${error}`)
        }
        finally {
            setSubmitting(false)
        }

    }

    const finishSubmit = async () => {

        if (action == 'save') {
            saveCategory()
        }
        else {
            updateCategory()
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
    }

    useEffect(() => {
        getCategories()

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

        return () => controller.abort()

    }, [errorMessages, currentPage, searchKeyword])

    const validateValues = (formData) => {
        let errorMessages = {}
        let forNameRegEx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/

        if (formData.name == '' || formData.name == '0') {
            errorMessages.name = 'Category required'
        }
        else if (!formData.name.match(forNameRegEx)) {
            errorMessages.name = 'Enter valid category'
        }

        if (formData.errorLabel == '' || formData.errorLabel == '0') {
            errorMessages.errorLabel = 'Error label required'
        }

        return errorMessages
    }

    return (
        <Layout>
            <AdminMenu userPermissions={auth?.user?.userPermission} />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <div className='row g-3 mb-4 align-items-center justify-content-between'>
                            <div className='col-auto'>
                                <h1 className='app-page-title mb-0'>Categories</h1>
                            </div>
                            <div className='col-auto'>
                                <div className='page-utilities'>
                                    <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                        <div className='col-auto'>
                                            <form className='docs-search-form row gx-1 align-items-center'>
                                                <div className='col-auto'>
                                                    <input type='text' name='searchKeyword' className='form-control search-docs' placeholder='Search' value={searchKeyword} onChange={(e) => {
                                                        setSearchKeyword(e.target.value)
                                                        setCurrentPage(1)
                                                    }} />
                                                </div>
                                            </form>

                                        </div>
                                        <div className='col-auto'>
                                            <button type='button' className='btn app-btn-primary' data-bs-toggle='modal' data-bs-target='#staticBackdrop' onClick={() => {
                                                setAction('save')
                                                clearFormData()
                                            }}>
                                                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-plus-lg' viewBox='0 0 16 16'>
                                                    <path fillRule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2' />
                                                </svg>&nbsp;Create Category
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='app-card app-card-orders-table shadow-sm mb-5'>
                            <div className='app-card-body'>
                                <div className='table-responsive'>
                                    <table className='table app-table-hover mb-0 text-left'>
                                        <thead>
                                            <tr>
                                                <th className='cell'>Name</th>
                                                <th className='cell'>Error Label</th>
                                                <th className='cell'>Created On</th>
                                                <th className='cell'>Updated On</th>
                                                <th className='cell'>Active</th>
                                                <th className='cell'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {categories?.map((category, index) =>
                                            (
                                                <tr key={category?._id}>
                                                    <td className='cell'><span className='truncate'>{category?.Name}</span></td>
                                                    <td className='cell'><span className='truncate'>{category?.ErrorLabel}</span></td>
                                                    <td className='cell'><span className='cell-data'>{category?.CreatedAt}</span></td>
                                                    <td className='cell'><span className='cell-data'>{category?.UpdatedAt}</span></td>
                                                    <td className='cell'>
                                                        <div className='form-check form-switch'>
                                                            <input className='form-check-input' type='checkbox' role='switch' id={`categoryActiveCheckbox${index}`} defaultChecked={category?.Active} onChange={() => {
                                                                handleStatusClick(index)
                                                            }} />
                                                            <input type='hidden' data-bs-toggle='modal' data-bs-target='#confirmDialog' id='confirmDialogButton' />
                                                        </div>
                                                    </td>
                                                    <td className='cell'>
                                                        <Link className='btn-sm text-decoration-none' to='' data-bs-toggle='modal' data-bs-target='#staticBackdrop' onClick={() => {
                                                            setFormData({ name: category?.Name, errorLabel: category?.ErrorLabel })
                                                            setSelectedValue(category)
                                                            setAction('update')
                                                        }} >
                                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-pencil-square' viewBox='0 0 16 16'>
                                                                <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                                                                <path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
                                                            </svg>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                            {
                                                categories.length <= 0 && <tr key={'a'}>
                                                    <td className='cell' colSpan={6}>No data available</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav className='app-pagination'>
                            <ul className='pagination justify-content-end'>
                                <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                                    <a className='page-link' href='#' tabIndex='-1' aria-disabled='true' onClick={() => handlePageChange(currentPage - 1)}>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left' viewBox='0 0 16 16'>
                                            <path fillRule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8' />
                                        </svg>&nbsp;Previous</a>
                                </li>
                                <li className={`page-item ${currentPage * pageSize >= totalCount ? 'disabled' : ''}`}>
                                    <a className='page-link' href='#' onClick={() => handlePageChange(currentPage + 1)}>Next&nbsp;
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                                            <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8' />
                                        </svg></a>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>

            <div className='modal fade' id='staticBackdrop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <CategoryForm handleSubmit={handleSubmit} formData={formData} handleInputChange={handleInputChange} errorMessages={errorMessages} submitting={submitting} />
                    </div>
                </div>
            </div>

            <div className='modal fade' id='confirmDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>

                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to deactivate?`}
                        onConfirm={handleStatus}
                        onCancel={handleCancelDeactivate}
                        arrayKey={arrayKey}
                    />
                )}

            </div>

        </Layout>
    )
}

export default Category