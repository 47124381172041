import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const ForgotPassword = () => {

    const [submitting, setSubmitting] = useState(false)
    const [errorMessages, setErrorMessages] = useState({})
    const [formData, setFormData] = useState({
        email: '',
    })

    const controller = new AbortController()
    const signal = controller.signal

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData))
        setSubmitting(true)
    }

    const validateValues = (data) => {
        let forEmailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let errorMessages = {}

        if (data.email == '') {
            errorMessages.email = 'Email required'
        }
        else if (!data.email.match(forEmailRegEx)) {
            errorMessages.email = 'Enter valid email'
        }

        return errorMessages
    }

    const finishSubmit = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    navigate('/login')
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to create account: ${error}`)
        }
        finally {
            setSubmitting(false)
        }
    }


    useEffect(() => {

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

        return () => controller.abort()
        //eslint-disable-next-line
    }, [errorMessages])

    return (
        <>
            <div className='row g-0 app-auth-wrapper'>
                <div className='col-12 col-md-7 col-lg-6 auth-main-col text-center p-5'>
                    <div className='d-flex flex-column align-content-end'>

                        <div className='app-auth-body mx-auto'>
                            <div className='app-auth-branding mb-3'><Link className='app-logo' to='/'><img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' /></Link></div>
                            <h2 className='auth-heading text-center mb-4'>Password Reset</h2>

                            <div className='auth-intro mb-4 text-center'>Enter your email address below. We'll email you a link to a page where you can easily create a new password.</div>

                            <div className='auth-form-container text-left'>

                                <form className='auth-form resetpass-form'>
                                    <div className='email mb-3'>
                                        <label className='sr-only'>Your Email</label>
                                        <input name='email' type='email' className='form-control login-email' placeholder='Enter your registered email' onChange={handleInputChange} />
                                        {errorMessages.email ? (<p className='form-text text-danger m-0'> {errorMessages.email}</p>) : <div className='form-text'>We'll send link on your registered email address.</div>}
                                    </div>
                                    <div className='text-center'>
                                        <button type='submit' className='btn app-btn-primary btn-block theme-btn mx-auto' onClick={handleSubmit} disabled={submitting}>Reset Password</button>
                                    </div>
                                    {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                                </form>
                                <div className='auth-option text-center pt-5'><Link className='app-link text-decoration-none' to='/login' >Log in</Link> <span className='px-2'>|</span> <Link className='app-link text-decoration-none' to='/register' >Sign up</Link></div>
                            </div>
                        </div>

                        <footer className='app-auth-footer'>
                            <div className='container text-center py-3'>
                                <small className='copyright'>&copy;{new Date().getFullYear()} ScanConnect&trade;</small>
                            </div>
                        </footer>
                    </div>
                </div>
                <div className='col-12 col-md-5 col-lg-6 h-100 auth-background-col'>
                    <div className='app-login auth-background-holder' style={{
                        backgroundImage: 'url(./Images/Background/background-1.jpg)',
                        backgroundSize: 'cover',
                        height: '100vh',
                        minHeight: '100%',
                    }}>
                    </div>
                    <div className='auth-background-mask'></div>
                    <div className='auth-background-overlay p-3 p-lg-5'>
                        <div className='d-flex flex-column align-content-end h-100'>
                            <div className='h-100'></div>
                            <div className='overlay-content p-3 p-lg-4 rounded'>
                                <h5 className='mb-3 overlay-title'>Unlock Possibilities</h5>
                                <div>Your Life, Your Way : Rent it all, effortlessly</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ForgotPassword

