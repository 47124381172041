import React, { useEffect, useRef, useState } from 'react';
import InternetSpeedChecker from '../../components/Common/InternetSpeedChecker'
import { useLocation } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { auth, db, storage } from '../../firebase';
import { doc, setDoc, collection, query, where, getDocs, updateDoc, serverTimestamp, getDoc, onSnapshot, arrayUnion, Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuid } from 'uuid'

const Chat = () => {

    const location = useLocation()
    const reference = useRef()
    const { connectData, userInfo } = location?.state || {}

    const [currentUser, setCurrentUser] = useState('')
    const [chatId, setChatId] = useState('')
    const [chats, setChats] = useState([])
    const [user, setUser] = useState(null)
    const [messages, setMessages] = useState([])
    const [text, setText] = useState('')
    const [image, setImage] = useState(null)
    const [isUserOnline, setIsUserOnline] = useState(true)

    // const init = async () => {
    //     try {
    //         //login user
    //         const res = await signInAnonymously(auth)
    //         setCurrentUser(res)

    //         //set user in firebase with id
    //         await setDoc(doc(db, 'Users', res.user.uid), {
    //             userId: res.user.uid,
    //             userName: userInfo.userName
    //         })

    //         //Get chats
    //         onSnapshot(doc(db, 'UserChats', res.user.uid), (doc) => {
    //             setChats(doc.data())
    //         })

    //         //select owner for chat
    //         const q = query(collection(db, 'Users'), where('userId', '==', connectData?.Owners[0]?._id))
    //         const querySnapshot = await getDocs(q)
    //         querySnapshot.forEach((doc) => {
    //             setUser(doc.data())
    //         })

    //         const combinedId = res.user.uid > connectData?.Owners[0]?._id ? res.user.uid + connectData?.Owners[0]?._id : connectData?.Owners[0]?._id + res.user.uid
    //         setChatId(combinedId)

    //         const chatData = await getDoc(doc(db, 'Chats', combinedId))

    //         if (!chatData.exists()) {

    //             //set initial user chats empty
    //             await setDoc(doc(db, 'UserChats', res.user.uid), {})

    //             //Create chat in Chats collections
    //             await setDoc(doc(db, 'Chats', combinedId), { messages: [] })

    //             //Create users chats
    //             await updateDoc(doc(db, 'UserChats', res.user.uid), {
    //                 [combinedId + '.userInfo']: {
    //                     uid: connectData?.Owners[0]?._id,
    //                     userName: connectData?.Owners[0]?.Name,
    //                     senderId: res.user.uid,
    //                     senderName: userInfo.userName
    //                 },
    //                 [combinedId + '.date']: serverTimestamp()
    //             })

    //             await updateDoc(doc(db, 'UserChats', connectData?.Owners[0]?._id), {
    //                 [combinedId + '.userInfo']: {
    //                     uid: res.user.uid,
    //                     userName: userInfo.userName,
    //                     senderId: connectData?.Owners[0]?._id,
    //                     senderName: connectData?.Owners[0]?.Name,
    //                 },
    //                 [combinedId + '.date']: serverTimestamp()
    //             })
    //         }
    //         else {
    //             onSnapshot(doc(db, 'Chats', combinedId), (doc) => {
    //                 doc.exists && setMessages(doc.data().messages)
    //             })
    //         }

    //     } catch (error) {
    //         console.log('Error: ', error)
    //         console.log('Error code: ', error.code)
    //         console.log('Error msg: ', error.message)
    //     }

    //     // return () => {
    //     //     unsub()
    //     // }
    // }

    // const init = async () => {
    //     try {
    //         //login user
    //         const res = await signInAnonymously(auth)
    //         setCurrentUser(res)

    //         //set user in firebase with id
    //         await setDoc(doc(db, 'Users', res.user.uid), {
    //             userId: res.user.uid,
    //             userName: userInfo.userName
    //         })

    //         //Get chats
    //         // onSnapshot(doc(db, 'UserChats', res.user.uid), (doc) => {
    //         //     setChats(doc.data())
    //         // })

    //         const combinedId = '65c11cc2125c9d9ace10d19d-UPve9OFgcDeEus8zzGYLSSOKmC62'

    //         await Firestore.collection('groupRoomId')
    //             .doc(combinedId)
    //             .collection('userCategoryGroup')
    //             .add({
    //                 'categoryid': '9',
    //                 'mobile': '5544556677',
    //                 'userId': '9',
    //                 'date': serverTimestamp()
    //             });




    //     } catch (error) {
    //         console.log('Error: ', error)
    //         console.log('Error code: ', error.code)
    //         console.log('Error msg: ', error.message)
    //     }

    //     // return () => {
    //     //     unsub()
    //     // }
    // }

    const init = async () => {
        try {
            //login user
            const res = await signInAnonymously(auth)
            setCurrentUser(res)

            document.addEventListener('visibilitychange', () => {
                handleVisibilityChange(res?.user?.uid)
            })
            //set user in firebase with id
            await setDoc(doc(db, 'Users', res.user.uid), {
                userId: res.user.uid,
                userName: userInfo.userName
            })

            //Get chats
            await setDoc(doc(db, 'UserChats', res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {})
            // onSnapshot(doc(db, 'UserChats', res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), (doc) => {
            //     setChats(doc.data())
            // })

            //select owner for chat
            const q = query(collection(db, 'Users'), where('userId', '==', connectData?.Owners[0]?._id))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
                setUser(doc.data())
            })

            const combinedId = res.user.uid > connectData?.Owners[0]?._id ? res.user.uid + connectData?.Owners[0]?._id : connectData?.Owners[0]?._id + res.user.uid
            setChatId(combinedId)

            const chatData = await getDoc(doc(db, 'Chats', combinedId))

            if (!chatData.exists()) {

                //set initial user chats empty
                // await setDoc(doc(db, 'UserChats', res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {})

                //Create chat in Chats collections
                await setDoc(doc(db, 'Chats', combinedId), { messages: [] })

                //Create users chats
                await updateDoc(doc(db, 'UserChats', res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {
                    ['userInfo']: {
                        uid: connectData?.Owners[0]?._id,
                        userName: connectData?.Owners[0]?.Name,
                        senderId: res.user.uid,
                        senderName: userInfo.userName
                    },
                    ['date']: serverTimestamp(),
                    ['isUserOnline']: isUserOnline,
                    ['combineId']: res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id,
                    ['groupId']: connectData?._id + '-' + connectData?.Owners[0]?._id

                })
                // await updateDoc(doc(db, 'UserChats', connectData?.Owners[0]?._id), {
                //     [combinedId + '.userInfo']: {
                //         uid: res.user.uid,
                //         userName: userInfo.userName,
                //         senderId: connectData?.Owners[0]?._id,
                //         senderName: connectData?.Owners[0]?.Name,
                //     },
                //     [combinedId + '.date']: serverTimestamp()
                // })
            }
            else {
                onSnapshot(doc(db, 'Chats', combinedId), (doc) => {
                    doc.exists && setMessages(doc.data().messages)
                })
            }

            updateDoc(doc(db, 'UserChats', res.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {
                ['isUserOnline']: isUserOnline
            })

        } catch (error) {
            console.log('Error: ', error)
            console.log('Error code: ', error.code)
            console.log('Error msg: ', error.message)
        }

        // return () => {
        //     unsub()
        // }
    }

    const getChats = (userId) => {
        const unsub = onSnapshot(doc(db, 'UserChats', userId), (doc) => {
            setChats(doc.data())
        })

        return () => {
            unsub()
        }
    }

    // const pushNotification = async (receiverId, senderId, senderName, lastMessage) => {
    //     requestForToken()

    //     const payload = {
    //         notification: {
    //             title: `${senderName}`,
    //             body: lastMessage,
    //         },
    //     }

    //     onMessageListener()
    //         .then((payload) => {
    //             setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    //         })
    //         .catch((err) => console.log('failed: ', err));
    // }

    const timestampToDateAndTime = (timestamp) => {
        // const dateObj = new Date(timestamp)
        const { seconds, nanoseconds } = timestamp

        // Format the date object as per your requirement
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,

        }

        const milliseconds = seconds * 1000 + nanoseconds / 1000000;
        const dateObj = new Date(milliseconds)
        return dateObj.toLocaleString('en-US', options)
    }

    const handleVisibilityChange = async (id) => {

        if (document.visibilityState == 'hidden') {
            setIsUserOnline(false)

            updateDoc(doc(db, 'UserChats', id + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {
                ['isUserOnline']: false
            })
        }
        else {
            setIsUserOnline(true)
            updateDoc(doc(db, 'UserChats', id + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id), {
                ['isUserOnline']: true
            })
        }


    }

    const sendPushNotification = async (fcmToken, conbinId, taggedId, groupId, notificationData) => {

        const serverKey = 'AAAANlMqssM:APA91bGb76E1jmJVulBENXiuY9ZwrlCDipNBXAk4SKPoXvrGr2u0wHAZTyYjOmwZZWYqfOL-xpnHE3wbYqwy1AfvdlT0Em-rF_u5BH9NpEy4g8i-UmPspL7H5AqxttVPea1GDnF-1Hi6'

        const message = {
            to: fcmToken,
            notification: {
                title: notificationData.title,
                body: notificationData.body,
            },
            data: {
                combineId: conbinId,
                taggedId: taggedId,
                groupId: groupId
            },
        };

        try {

            const response = await fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `key=${serverKey}`,
                },
                body: JSON.stringify(message),
            });

            if (!response.ok) {
                throw new Error('Failed to send push notification.')
            }

        } catch (error) {
            console.error('Error sending push notification:', error)
        }
    }

    const handleSend = async () => {
        try {

            const conbinId = currentUser.user.uid + '-' + connectData?._id + '-' + connectData?.Owners[0]?._id
            const groupId = connectData?._id + '-' + connectData?.Owners[0]?._id

            if (text) {

                if (image) {
                    const storageRef = ref(storage, uuid())
                    const uploadTask = uploadBytesResumable(storageRef, image)

                    uploadTask.on(
                        (error) => {
                            console.log('Image Error: ', JSON.stringify(error))
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                await updateDoc(doc(db, 'Chats', chatId), {
                                    messages: arrayUnion({
                                        id: uuid(),
                                        text: text,
                                        senderId: currentUser.user.uid,
                                        date: Timestamp.now(),
                                        image: downloadURL
                                    })
                                })
                            })
                        }

                    )
                }
                else {
                    await updateDoc(doc(db, 'Chats', chatId), {
                        messages: arrayUnion({
                            id: uuid(),
                            text: text,
                            senderId: currentUser.user.uid,
                            date: Timestamp.now()
                        })
                    })
                }

                //res.user.uid+'-'+connectData?._id+'-'+connectData?.Owners[0]?._id

                // await updateDoc(doc(db, 'UserChats', conbinId), {
                //     [chatId + '.lastMessage']: {
                //         text: text
                //     },
                //     [chatId + '.date']: serverTimestamp(),
                //     ['lastMessage']: {
                //         text: text
                //     },
                //     ['date']: serverTimestamp()
                // })


                await updateDoc(doc(db, 'UserChats', conbinId), {
                    ['userInfo']: {
                        uid: connectData?.Owners[0]?._id,
                        userName: connectData?.Owners[0]?.Name,
                        senderId: currentUser.user.uid,
                        senderName: userInfo.userName
                    },
                    ['date']: serverTimestamp(),
                    ['isUserOnline']: isUserOnline,
                    ['combineId']: conbinId,
                    ['groupId']: groupId,
                    ['lastMessage']: {
                        text: text
                    }
                })

                onSnapshot(doc(db, 'Chats', chatId), (doc) => {
                    doc.exists && setMessages(doc.data().messages)
                })

                // pushNotification(connectData?.Owners[0]?._id, currentUser.user.uid, userInfo.userName, text)

                // await updateDoc(doc(db, 'UserChats', connectData?.Owners[0]?._id), {
                //     [chatId + '.lastMessage']: {
                //         text: text
                //     },
                //     [chatId + '.date']: serverTimestamp()
                // })
            }
            setText('')
            setImage(null)

            const fcmToken = connectData?.Owners[0]?.FcmToken

            const notificationData = {
                title: 'ScanConnect',
                body: 'You have new message',
            };

            sendPushNotification(fcmToken, conbinId, connectData?._id, groupId, notificationData);

        } catch (error) {
            console.log('Error: ', JSON.stringify(error))
            console.log('Error code: ', error.code)
            console.log('Error msg: ', error.message)
        }
    }

    const handleKey = (e) => {
        if (e.code == 'Enter') {
            handleSend()
        }
    }

    useEffect(() => {
        init()

        return () => {
            document.addEventListener('visibilitychange', () => {
                handleVisibilityChange(currentUser?.user?.uid)
            })
        }
    }, [])

    useEffect(() => {
        reference.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])

    return (
        <InternetSpeedChecker>
            {/* <div className='container'> */}
            {/* <div className='mt-5'> */}

            <div className='card'>
                <div className='card-header'>
                    <p>
                        {user && user.userName}
                    </p>
                </div>
                <div className='card-body'>
                    {/* <div className='card-title'>
                                
                            </div> */}
                    <div className='container-fluid'>
                        <div id='messages_container' className='chat-log'>
                            {
                                messages?.map((message, index) => (

                                    < div key={message.id} ref={reference} className={`chat-log_item ${message.senderId == currentUser.user.uid ? `chat-log_item-own` : `chat-log_item`} z-depth-0`}>
                                        <div className='chat-log_message' >
                                            <p>{message.text}</p>
                                            {message.image && <img src={message.image} alt='' />}
                                        </div>
                                        <div className='row chat-log_time m-0 p-0 justify-content-end'>
                                            <span className='chat-log_author'>
                                                {
                                                    timestampToDateAndTime(message.date)
                                                }
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='card-footer border-0 bottom-rounded z-depth-0' style={{ backgroundColor: '#97E3C2' }}>
                    <div className='row'>
                        <div className='col col-md-12 col-lg-12 mx-auto'>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-12 col-md-12 align-self-center my-0'>
                                    <div className='row d-flex align-self-center justify-content-center'>
                                        <div className='col-12 d-flex'>
                                            <div className='form-group col-10 my-0 mx-0'>
                                                <input type='text' placeholder='Type something...' onKeyDown={handleKey} onChange={(e) => setText(e.target.value)} value={text} className='form-control' />
                                            </div>
                                            {/* <div className='form-group col-2 my-0 mx-0'>
                                                <img src={image} alt='' />
                                                <input type='file' style={{ display: 'none' }} id='file' onChange={(e) => setImage(e.target.files[0])} />
                                                <label htmlFor='file' className='pe-auto'>
                                                    <img src={process.env.PUBLIC_URL + '/Images/Chat/attach.png'} alt='' />
                                                </label>
                                            </div> */}
                                            <div className='form-group col-2 my-0 mx-3'>
                                                <button onClick={handleSend} type='button' className='btn app-btn-primary'><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-send' viewBox='0 0 16 16'>
                                                    <path d='M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z' />
                                                </svg></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </InternetSpeedChecker >
    )
}

export default Chat