import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const Login = () => {

    const [auth, setAuth] = useAuth()
    const [submitting, setSubmitting] = useState(false)
    const [errorMessages, setErrorMessages] = useState({})
    const [showPassword, setShowPassword] = useState(false)

    const [formData, setFormData] = useState({
        mobile: '',
        password: '',
        rememberMe: false
    })

    const navigate = useNavigate()

    const handleInputChange = (e) => {

        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const validateValues = (formData) => {
        let forMobileRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        let errorMessages = {}

        if (formData.mobile == '') {
            errorMessages.mobile = 'Mobile required'
        }
        else if (forMobileRegEx.test(formData.mobile) == false) {
            errorMessages.mobile = 'Enter valid mobile'
        }

        if (formData.password == '') {
            errorMessages.password = 'Password required'
        }

        return errorMessages;
    }

    const finishSubmit = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {
                    sendOTP(apiResponse?.data?.mobile, apiResponse?.data?.email)
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to login: ${error}`)
        }
        finally {
            setSubmitting(false)
        }
    }

    const sendOTP = async (mobile, email) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: mobile, rememberMe: formData.rememberMe })
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {
                    navigate('/otp-auth', { state: { mobile: mobile, email: email, rememberMe: formData.rememberMe, register: false } })
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to login: ${error}`)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData));
        setSubmitting(true);
    }

    useEffect(() => {

        if (auth?.user?.token) {
            navigate(-1 || '/')
        }

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

    }, [errorMessages, auth]);

    return (
        <>
            <div className='row g-0 app-auth-wrapper'>
                <div className='col-12 col-md-7 col-lg-6 auth-main-col text-center p-5'>
                    <div className='d-flex flex-column align-content-end'>
                        <div className='app-auth-body mx-auto'>

                            <div className='app-auth-branding mb-3'><Link className='app-logo text-decoration-none' to='/'><img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' /></Link></div>
                            <h2 className='auth-heading text-center mb-4'>Rent Your World with Ease!</h2>
                            <div className='auth-form-container text-start'>
                                <form className='auth-form login-form'>
                                    <div className='email mb-3'>
                                        <label className='sr-only' >Mobile</label>
                                        <input type='text' name='mobile' maxLength={10} value={formData.mobile} className='form-control' placeholder='Mobile number' onChange={handleInputChange} />
                                        {errorMessages.mobile ? (<p className='text text-danger mb-0 position-absolute'> {errorMessages.mobile}</p>) : null}
                                    </div>

                                    <div className='password mb-3'>
                                        <label className='sr-only'>Password</label>
                                        <div className='input-group'>
                                            <input type={`${showPassword ? 'text' : 'password'}`} name='password' value={formData.password} className='form-control' onChange={handleInputChange} placeholder='Enter password' />

                                            <button className='input-group-text' type='button' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                    <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                    <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                    <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                    <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                    <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                </svg>}
                                            </button>
                                        </div>
                                        {errorMessages.password ? (<p className='text text-danger mb-0 position-absolute'> {errorMessages.password}</p>) : null}
                                        <div className='extra mt-3 row justify-content-between'>
                                            <div className='col-6'>
                                                <div className='form-check'>
                                                    <input className='form-check-input' type='checkbox' name='rememberMe' value={formData.rememberMe} onChange={(e) => {
                                                        setFormData((prevData) => ({
                                                            ...prevData,
                                                            rememberMe: e.target.checked
                                                        }))
                                                    }} id='RememberPassword' />
                                                    <label className='form-check-label' htmlFor='RememberPassword'>
                                                        Keep me signed in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='forgot-password text-end'>
                                                    <Link className='text-decoration-none' to='/forgot-password'>Forgot password?</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <button type='submit' onClick={handleSubmit} className='btn app-btn-primary w-100 theme-btn mx-auto' disabled={submitting}>Log In</button>
                                        {errorMessages.response ? (<p className='text text-danger mb-0 position-absolute'> {errorMessages.response}</p>) : null}
                                    </div>
                                </form>
                                <div className='auth-option text-center pt-5'>No Account? Sign up <Link className='text-link text-decoration-none' to='/register' >here</Link>.</div>
                            </div>
                        </div>

                        <footer className='app-auth-footer'>
                            <div className='container text-center py-3'>
                                <small className='copyright'>&copy;{new Date().getFullYear()} ScanConnect&trade;</small>
                            </div>
                        </footer>
                    </div>
                </div>
                <div className='col-12 col-md-5 col-lg-6 h-100 auth-background-col'>
                    <div className='app-login auth-background-holder' style={{
                        backgroundImage: 'url(./Images/Background/background-3.jpg)',
                        backgroundSize: 'cover',
                        height: '100vh',
                        minHeight: '100%',
                    }}>
                    </div>
                    <div className='auth-background-mask'></div>
                    <div className='auth-background-overlay p-3 p-lg-5'>
                        <div className='d-flex flex-column align-content-end h-100'>
                            <div className='h-100'></div>
                            <div className='overlay-content p-3 p-lg-4 rounded'>
                                <h5 className='mb-3 overlay-title'>Unlock Possibilities</h5>
                                <div>Your Life, Your Way : Rent it all, effortlessly</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Login

