import React, { useEffect, useState } from 'react'
// import QrReader from 'react-qr-scanner'
import { useLocation, useNavigate } from 'react-router-dom'

const Scan = () => {

    const [errorMessages, setErrorMessages] = useState({})
    // const [scan, setScan] = useState('')
    const navigate = useNavigate()
    const location = useLocation()


    const handleScan = async (scanData) => {
        // setResult(scanData.text);
        try {
            if (scanData != '') {
                // setScan(scanData.text)
                let errorMessages = {}

                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/qr/scan-qr`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ qrScanData: scanData })
                })

                if (response.ok) {
                    const apiResponse = await response.json()
                    if (apiResponse.success) {
                        navigate('/connect', { state: apiResponse.data })
                    }
                    else {
                        errorMessages.response = apiResponse.message
                        navigate('/message', { state: { title: 'Oops!', text: apiResponse.message } })

                        // setErrorMessages(errorMessages)
                    }
                }
                else {
                    throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
                }
            }
            else {
                errorMessages.response = 'Invalid QR'
                navigate('/message', { state: { title: 'Oops!', text: 'Invalid QR' } })

                // setErrorMessages(errorMessages)
            }
        }
        catch (error) {
            errorMessages.response = `Error : ${error}`
            setErrorMessages(errorMessages)
        }
    }

    const handleError = (error) => {
        let errorMessages = {}
        errorMessages.response = `QR code scan error: ${error}`
        setErrorMessages(errorMessages)
    }

    useEffect(() => {
        handleScan(location?.pathname?.replace('/', ''))
    }, [])

    return (
        // <Layout>
        <div className='app-content pt-3 p-md-3 p-lg-4'>
            <div className='container-xl'>
                {/* <h1 className='app-page-title'>Scan</h1> */}
                <div className='row no-gutters justify-content-center'>

                    {/* <QrReader delay={500} onError={handleError} facingmode={'environment'} onScan={handleScan} /> */}
                    {errorMessages?.response ? (<p className='text text-danger mb-0 position-absolute'> {errorMessages?.response}</p>) : null}
                </div>
            </div>
        </div>

        // </Layout>
    )
}

export default Scan