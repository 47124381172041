import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '../../context/auth'

const Header = () => {

    const [auth, setAuth] = useAuth()
    const [avatar, setAvatar] = useState('')
    const [errorMessages, setErrorMessages] = useState({})

    const handleLogout = () => {
        setAuth({
            ...auth,
            user: null,
            token: ''
        })

        localStorage.removeItem('auth')
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        try {
            let errorMessages = {}
            setErrorMessages('')
            const formData = new FormData();
            formData.append('avatar', e.target.files[0])

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/upload-avatar`, {
                method: 'POST',
                headers: {
                    Authorization: auth?.token,
                },
                body: formData
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse?.success) {
                    setAuth({
                        ...auth,
                        avatar: apiResponse?.data
                    })
                    const local = JSON.parse(localStorage.getItem('auth'))
                    local.avatar = apiResponse?.data
                    setAvatar(local.avatar)
                    localStorage.setItem('auth', JSON.stringify(local))
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to upload avatar: ${error}`)
        }
    }

    useEffect(() => {
        const local = JSON.parse(localStorage.getItem('auth'))
        local && setAvatar(local.avatar)
    }, [])

    return (
        <>
            <header className='app-header fixed-top'>
                <div className='app-header-inner'>
                    <div className='container-fluid py-2'>
                        <div className='app-header-content'>
                            <div className='row justify-content-between align-items-center'>

                                {/* Mobile responsive logo */}
                                <div className='app-utilities col-auto d-inline-block d-xl-none d-lg-none d-md-none d-sm-block'>
                                    <div className='app-utility-item'>
                                        <NavLink className='navbar-brand ' to='/'>
                                            <img className='logo-icon me-2 img-responsive' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' height={40} />
                                        </NavLink>
                                    </div>
                                </div>

                                {/* Big screen logo */}
                                <div className='app-utilities col-auto d-md-block d-xl-block d-lg-block d-none d-sm-block'>
                                    <div className='app-utility-item'>
                                        <NavLink className='navbar-brand position-relative' to='/'>
                                            <img className='logo-icon me-2 img-responsive' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogo.svg`} alt='ScanConnect Logo' height={40} />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='app-utilities col-auto'>
                                    <div className='app-utility-item'>
                                        <NavLink className='nav-link' aria-current='page' title='Home' to='/'>Home</NavLink>
                                    </div>
                                    {errorMessages.response ? (<p className='text text-danger mb-0 mt-1 position-absolute'> {errorMessages.response}</p>) : null}
                                    {
                                        !auth.user ? (
                                            <>
                                                <div className='app-utility-item'>
                                                    <NavLink className='nav-link' aria-current='page' title='Register' to='/register'>Register</NavLink>
                                                </div>
                                                <div className='app-utility-item'>
                                                    <NavLink className='nav-link' aria-current='page' title='Login' to='/login'>Login</NavLink>
                                                </div>
                                            </>
                                        ) : (
                                            <>

                                                <div className='app-utility-item app-user-dropdown dropdown'>
                                                    <Link className='dropdown-toggle text-decoration-none' id='user-dropdown-toggle' data-bs-toggle='dropdown' to='#' role='button' aria-expanded='false'>
                                                        {
                                                            !avatar || avatar == '' ? auth?.user?.name : (<img src={process.env.REACT_APP_API_BASE_URL + avatar} className='img-fluid rounded-circle' alt={auth?.user?.name} />)
                                                        }

                                                    </Link>
                                                    <ul className='dropdown-menu' aria-labelledby='user-dropdown-toggle'>
                                                        <li>
                                                            <Link className='dropdown-item fs-6' to={`/dashboard/${auth?.user?.role == 1 ? 'admin' : 'user'}`}>Dashboard</Link>
                                                        </li>
                                                        <li>
                                                            <input type='file' className='form-control visually-hidden' id='imageUpload' aria-label='Avatar' onChange={handleImageChange} />
                                                            <label htmlFor='imageUpload' className='dropdown-item fs-6'>Avatar</label>
                                                        </li>
                                                        <li>
                                                            <hr className='dropdown-divider' />
                                                        </li>
                                                        <li>
                                                            <NavLink onClick={handleLogout} to='/login' className='dropdown-item fs-6'>Log Out</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header