import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import OwnerMenu from '../../components/Layouts/OwnerMenu'
import { useAuth } from '../../context/auth'
import { Link } from 'react-router-dom'
import ConfirmationDialog from '../../components/Common/ConfirmationDialog'

const OwnerTaggedQr = () => {

    const [auth, setAuth] = useAuth()
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const [timeFilter, setTimeFilter] = useState('all')
    const [searchKeyword, setSearchKeyword] = useState('')
    const [activeStatus, setActiveStatus] = useState('')
    const [taggedQrs, setTaggedQrs] = useState([])
    const [errorMessages, setErrorMessages] = useState({})
    const [qrImage, setQrImage] = useState('')
    const [arrayKey, setArrayKey] = useState('')

    const controller = new AbortController()
    const signal = controller.signal

    const getImage = async (key) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/qr/image/${taggedQrs[key]?.Scan}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    setQrImage(apiResponse?.data?.Image)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to get QR image: ${error}`)
        }
    }

    const handleStatusClick = (key) => {

        setArrayKey(key)

        if (taggedQrs[key]?.Active) {
            const confirmDialogButton = document.getElementById('confirmDialogButton')
            confirmDialogButton.click()
        } else {
            handleStatus(key)
        }
    }

    const handleCancelDeactivate = () => {

        if (taggedQrs[arrayKey]?.Active) {
            document.querySelector(`#taggedActiveCheckbox${arrayKey}`).checked = true
        } else {
            document.querySelector(`#taggedActiveCheckbox${arrayKey}`).checked = false
        }
    }

    const handleStatus = async (key) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/qr/tagged-qr-active`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify({ id: taggedQrs[key]?._id, active: taggedQrs[key]?.Active ? 0 : 1 }),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getTaggedQrs()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to change status: ${error}`)
        }
    }


    const handleDelete = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/qr/tagged-qr-delete/${taggedQrs[arrayKey]?._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getTaggedQrs()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to delete tagged QR: ${error}`)
        }
    }

    const handleCancelDelete = () => {
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
    }

    const getTaggedQrs = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/qr/owner-tagged-qrs/?page=${currentPage}&limit=${pageSize}&active=${activeStatus}&search=${searchKeyword}&time=${timeFilter}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setTaggedQrs(apiResponse?.data?.TaggedQrs)
                    setTotalCount(apiResponse?.data?.TotalCount)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                setTaggedQrs([])
                setTotalCount(0)
                throw new Error(`Network response was not ok, status:${response.status}, message:${response.statusText}`)
            }

        } catch (error) {
            console.log(`Unable to fetch tagged QRs: ${error}`)
        }
    }

    useEffect(() => {
        getTaggedQrs()
        return () => controller.abort()
    }, [currentPage, searchKeyword, timeFilter, activeStatus])

    return (
        <Layout>
            <OwnerMenu />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <div className='row g-3 mb-4 align-items-center justify-content-between'>
                            <div className='col-auto'>
                                <h1 className='app-page-title mb-0'>Activated QRs</h1>
                            </div>
                            <div className='col-auto'>
                                <div className='page-utilities'>
                                    <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                        <div className='col-auto'>
                                            <form className='table-search-form row gx-1 align-items-center'>
                                                <div className='col-auto'>
                                                    <input type='text' name='searchKeyword' className='form-control search-orders' placeholder='Search' value={searchKeyword} onChange={(e) => {
                                                        setSearchKeyword(e.target.value)
                                                        setCurrentPage(1)
                                                    }} />
                                                </div>
                                            </form>

                                        </div>
                                        <div className='col-auto'>

                                            <select value={timeFilter} className='form-select w-auto' onChange={(e) => {
                                                setTimeFilter(e.target.value)
                                            }} >
                                                <option value='all'>All</option>
                                                <option value='thisWeek'>This week</option>
                                                <option value='thisMonth'>This month</option>
                                                <option value='lastThreeMonths'>Last 3 months</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <nav id='owner-all-tab' className='owner-all-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4'>
                            <a className='flex-sm-fill text-sm-center nav-link active' data-bs-toggle='tab' role='tab' aria-selected='true' onClick={() => {
                                setActiveStatus('')
                                setCurrentPage(1)
                            }}>
                                All
                            </a>
                            <a className='flex-sm-fill text-sm-center nav-link' data-bs-toggle='tab' role='tab' aria-selected='false' onClick={() => {
                                setActiveStatus(1)
                                setCurrentPage(1)
                            }}>
                                Active
                            </a>
                            <a className='flex-sm-fill text-sm-center nav-link' data-bs-toggle='tab' role='tab' aria-selected='false' onClick={() => {
                                setActiveStatus(0)
                                setCurrentPage(1)
                            }}>
                                Inactive
                            </a>

                        </nav>


                        <div className='tab-content' id='owner-all-tab-content'>
                            <div className='tab-pane fade show active' id='owner-all' role='tabpanel' aria-labelledby='owner-all-tab'>
                                <div className='app-card app-card-orders-table shadow-sm mb-5'>
                                    <div className='app-card-body'>
                                        <div className='table-responsive'>
                                            <table className='table app-table-hover mb-0 text-left'>
                                                <thead>
                                                    <tr>
                                                        <th className='cell'>Product</th>
                                                        <th className='cell'>Address</th>
                                                        <th className='cell'>Description</th>
                                                        <th className='cell'>QR</th>
                                                        <th className='cell'>Created On</th>
                                                        <th className='cell'>Updated On</th>
                                                        <th className='cell'>Active</th>
                                                        <th className='cell'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        taggedQrs?.map((taggedQr, index) => (
                                                            <tr key={taggedQr?._id}>
                                                                <td className='cell'>
                                                                    <span>
                                                                        {taggedQr?.Number ? taggedQr?.Number : taggedQr?.LostItemName}
                                                                    </span>
                                                                    <span className='note'>{taggedQr?.CategoryName}</span>
                                                                </td>
                                                                <td className='cell'>
                                                                    <span className='truncate'>{taggedQr?.Address}</span>
                                                                </td>
                                                                <td className='cell'>
                                                                    <span className='truncate'>{taggedQr?.Description}</span>
                                                                </td>
                                                                <td className='cell'>

                                                                    {taggedQr?.Scan ? (
                                                                        <>
                                                                            <Link className='text-decoration-none' to='' data-bs-toggle='modal' data-bs-target='#qrImageView' onClick={() => {
                                                                                getImage(index)
                                                                            }}>
                                                                                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-door-open text-primary' viewBox='0 0 16 16'>
                                                                                    <path d='M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1' />
                                                                                    <path d='M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117M11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5M4 1.934V15h6V1.077z' />
                                                                                </svg>
                                                                            </Link>
                                                                        </>
                                                                    ) : '-'}
                                                                </td>
                                                                <td className='cell'><span className='cell-data'>{taggedQr?.CreatedAt}</span></td>
                                                                <td className='cell'><span className='cell-data'>{taggedQr?.UpdatedAt}</span></td>
                                                                <td className='cell'>
                                                                    <div className='form-check form-switch'>
                                                                        <input className='form-check-input' type='checkbox' role='switch' id={`taggedActiveCheckbox${index}`} defaultChecked={taggedQr?.Active} onChange={() => {
                                                                            handleStatusClick(index)
                                                                        }} />
                                                                        <input type='hidden' data-bs-toggle='modal' data-bs-target='#confirmDeactivateDialog' id='confirmDialogButton' />
                                                                    </div>
                                                                </td>
                                                                <td className='cell'>
                                                                    <Link className='text-decoration-none p-0' to='' data-bs-toggle='modal' data-bs-target='#confirmDeleteDialog' onClick={() => {
                                                                        setArrayKey(index)
                                                                    }}>
                                                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-trash text-danger' viewBox='0 0 16 16'>
                                                                            <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z' />
                                                                            <path d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z' />
                                                                        </svg>

                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <nav className='app-pagination'>
                                <ul className='pagination justify-content-end'>
                                    <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                                        <a className='page-link' href='#' tabIndex='-1' aria-disabled='true' onClick={() => handlePageChange(currentPage - 1)}>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left' viewBox='0 0 16 16'>
                                                <path fillRule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8' />
                                            </svg>&nbsp;Previous</a>
                                    </li>
                                    <li className={`page-item ${currentPage * pageSize >= totalCount ? 'disabled' : ''}`}>
                                        <a className='page-link' href='#' onClick={() => handlePageChange(currentPage + 1)}>Next&nbsp;
                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                                                <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8' />
                                            </svg></a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>


            <div className='modal fade' id='qrImageView' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                <div className='modal-dialog modal-sm modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-body'>

                            <button type='button' className='position-absolute top-0 end-0' data-bs-dismiss='modal' aria-label='Close'>
                                <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark'>&times;</span>
                                <span className='visually-hidden'>&times;</span>
                            </button>

                            <img className='rounded mx-auto d-block img-fluid img-thumbnail' src={qrImage} alt='QR Image' />
                        </div>
                        <div className='position-absolute bottom-0 end-0'>
                            <Link download={`${process.env.REACT_APP_NAME}_${new Date().toISOString()}.png`} className='btn-sm' to={qrImage}>
                                <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-download me-2' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                                    <path fillRule='evenodd' d='M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z' />
                                    <path fillRule='evenodd' d='M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z' />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal fade' id='confirmDeactivateDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to deactivate?`}
                        onConfirm={handleStatus}
                        onCancel={handleCancelDeactivate}
                        arrayKey={arrayKey}
                    />
                )}
            </div>

            <div className='modal fade' id='confirmDeleteDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to delete?`}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        arrayKey={arrayKey}
                    />
                )}
            </div>

        </Layout>
    )
}

export default OwnerTaggedQr