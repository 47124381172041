import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const RegistrationSuccess = () => {

    const location = useLocation()
    const { name } = location.state

    return (

        <div className='container'>
            <div className='row mt-5'>
                <div className='col-md-6 offset-md-3'>
                    <div className='card border-0 shadow-sm rounded-lg p-4' style={{ background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)' }}>
                        <div className='card-body text-center'>
                            <img src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' className='mb-3' style={{ width: '100px' }} />
                            <h3 className='text-white'>Registration Successful!</h3>
                            <p className='text-white'>Hello <strong>{name}</strong>, thank you for registering.</p>
                            <p className='text-white'>You can now login using our app.</p>
                            <p className='text-white'>Click <a href='https://play.google.com' className='text-white'>here</a> to download the app.</p>
                            <p className='text-white'>Or go to <Link to='/login' className='text-white'>Login</Link> page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RegistrationSuccess