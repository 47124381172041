
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyALc0te47vpln2qeseU116NTGjh5-F_ZrA",
    authDomain: "scanconnects-47d23.firebaseapp.com",
    projectId: "scanconnects-47d23",
    storageBucket: "scanconnects-47d23.appspot.com",
    messagingSenderId: "233323541187",
    appId: "1:233323541187:web:18c06dd8b1f35d09d56876",
    measurementId: "G-NFS0BQFH3H"
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const storage = getStorage()
export const db = getFirestore()
const analytics = getAnalytics(app)