import React from 'react'
import { useLocation } from 'react-router-dom'


const Message = () => {

    const location = useLocation()
    const content = location?.state

    return (
        <div className='container'>
            <div className='row align-items-center vh-100'>
                <div className='rounded'>
                    <div className='app-auth-branding mb-2 app-logo text-decoration-none text-center '>
                        <img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' />
                    </div>
                    <div className='card border-secondary text-center w-95 shadow'>
                        <div className='card-header'>
                            <img className='logo-icon me-2 img-responsive' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogo.svg`} alt='ScanConnect Logo' height={40} />
                        </div>
                        <div className='card-body'>
                            <h5 className='card-title'>
                                <h1 className='display-4'>{content?.title}</h1>
                            </h5>

                            <div className='col-lg-12 col-md-12 col-xs-12'>
                                <p className='fw-normal'>{content?.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Message