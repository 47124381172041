import React, { useEffect } from 'react' // Importing useEffect for lifecycle events
import { Link, NavLink } from 'react-router-dom'

const AdminMenu = () => {

    const menuItems = [
        {
            name: 'Activated QRs',
            url: 'tagged-qrs',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-qr-code-scan' viewBox='0 0 16 16'>
                <path d='M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z' />
                <path d='M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z' />
                <path d='M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z' />
                <path d='M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z' />
                <path d='M12 9h2V8h-2z' />
            </svg>
        },
        {
            name: 'Call Logs',
            url: 'call-logs',
            icon: <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-telephone' viewBox='0 0 16 16'>
                <path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z' />
            </svg>
        },
    ]

    const sidePanelToggler = () => {
        const sidePanel = document.getElementById('app-sidepanel')

        if (sidePanel.classList.contains('sidepanel-visible')) {
            sidePanel.classList.remove('sidepanel-visible')
            sidePanel.classList.add('sidepanel-hidden')
        } else {
            sidePanel.classList.remove('sidepanel-hidden')
            sidePanel.classList.add('sidepanel-visible')
        }
    }

    useEffect(() => {
        // Function to handle responsive side panel on window load and resize
        const handleResize = () => {
            const w = window.innerWidth
            const sidePanel = document.getElementById('app-sidepanel')

            if (w >= 1200) {
                sidePanel.classList.remove('sidepanel-hidden')
                sidePanel.classList.add('sidepanel-visible')
            } else {
                sidePanel.classList.remove('sidepanel-visible')
                sidePanel.classList.add('sidepanel-hidden')
            }
        }

        // Add event listeners for window load and resize
        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)

        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener('load', handleResize)
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const sidePanelClose = (e) => {
        e.preventDefault()
        document.getElementById('sidepanel-toggler').click()
    }

    const sidePanelDrop = (e) => {
        e.preventDefault()
        document.getElementById('sidepanel-toggler').click()
    }

    return (
        <>
            {/* Sidebar implementation */}

            <div className='col-auto'>
                <Link id='sidepanel-toggler' onClick={sidePanelToggler} className='sidepanel-toggler d-inline-block d-xl-none' to='#'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' role='img'><title>Menu</title><path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'></path></svg>
                </Link>
            </div>

            <div id='app-sidepanel' className='app-sidepanel z-3'>
                <div id='sidepanel-drop' onClick={sidePanelDrop} className='sidepanel-drop'></div>
                <div className='sidepanel-inner d-flex flex-column'>
                    <NavLink to='#' id='sidepanel-close' onClick={sidePanelClose} className='sidepanel-close d-xl-none text-decoration-none'>&times</NavLink>

                    <nav id='app-nav-main' className='app-nav app-nav-main flex-grow-1'>
                        <ul className='app-menu list-unstyled accordion' id='menu-accordion'>
                            {menuItems.map(item => (
                                // Check if the user has url for this menu item

                                <MenuItem key={item.name} item={item} />

                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}

const MenuItem = ({ item }) => {
    const { name, icon, url, submenuItems } = item


    return (

        <li className='nav-item'>
            {submenuItems ? (
                // Render submenu with conditional rendering
                <ul className='submenu-list list-unstyled'>
                    <SubMenu item={item} />
                </ul>
            ) : (
                // Render single menu item
                <NavLink className='nav-link' to={`/dashboard/owner/${url}`}>
                    <span className='nav-icon'>{icon}</span>
                    <span className='nav-link-text'>{name}</span>
                </NavLink>
            )}
        </li>
    )
}

const SubMenu = ({ item }) => {
    const { name, icon, arrowIcon, url, submenuItems } = item

    return (
        <li className='nav-item has-submenu'>
            {/* Submenu toggle */}
            <Link className='nav-link submenu-toggle' to={''} data-bs-toggle='collapse' data-bs-target={`#${url}SubMenu`} aria-expanded='false'>
                <span className='nav-icon'>{icon}</span>
                <span className='nav-link-text'>{name}</span>
                <span className='submenu-arrow'>{arrowIcon}</span>
            </Link>
            {/* Submenu items */}

            <div id={`${url}SubMenu`} className='collapse submenu' data-bs-parent='#menu-accordion'>
                <ul className='submenu-list list-unstyled'>
                    {submenuItems.map(submenuItem => (
                        <MenuItem key={submenuItem.name} item={submenuItem} />
                    ))}
                </ul>
            </div>
        </li>
    )
}

export default AdminMenu
