import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import AdminMenu from '../../components/Layouts/AdminMenu'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import ConfirmationDialog from '../../components/Common/ConfirmationDialog'


const SubAdmin = () => {

    const [auth, setAuth] = useAuth()
    const [countries, setCountries] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [subAdmins, setSubAdmins] = useState([])
    const [errorMessages, setErrorMessages] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [action, setAction] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [arrayKey, setArrayKey] = useState('')
    let [permissions, setPermissions] = useState(['qrs', 'tagged-qrs', 'owners', 'settings', 'categories', 'subAdmins'])
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        country: '',
        userPermission: permissions.join('|'),
        password: ''
    })
    const controller = new AbortController()
    const signal = controller.signal

    const clearFormData = () => {
        setFormData({
            name: '',
            mobile: '',
            email: '',
            userPermission: permissions.join('|'),
            password: ''
        })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData))
        setSubmitting(true)
    }

    const handleStatusClick = (key) => {

        setArrayKey(key)

        if (subAdmins[key]?.Active) {
            const confirmDialogButton = document.getElementById('confirmDialogButton')
            confirmDialogButton.click()
        } else {
            handleStatus(key)
        }
    }

    const handleCancelDeactivate = () => {

        if (subAdmins[arrayKey]?.Active) {
            document.querySelector(`#subAdminActiveCheckbox${arrayKey}`).checked = true
        } else {
            document.querySelector(`#subAdminActiveCheckbox${arrayKey}`).checked = false
        }
    }

    const handleStatus = async (key) => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/owner-active`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify({ id: subAdmins[key]?._id, active: subAdmins[key]?.Active ? false : true }),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getSubAdmins()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to change status: ${error}`)
        }

    }

    const fetchIpInfo = async () => {
        try {

            const response = await fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            const apiResponse = await response.json()

            if (apiResponse?.error) {

            }
            else {
                setFormData((prevData) => ({
                    ...prevData,
                    country: apiResponse?.country,
                }))
            }

            getCountries()
        }
        catch (error) {
            console.log(`Unable to fetch IP info: ${error}`)
        }
    }

    const getCountries = async () => {
        try {
            var errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/countries`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    setCountries(apiResponse?.data)
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to fetch countries: ${error}`)
        }
    }

    const getSubAdmins = async () => {

        try {

            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/admin/all-subadmins/?page=${currentPage}&limit=${pageSize}&search=${searchKeyword}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setSubAdmins(apiResponse?.data?.Owners)
                    setTotalCount(apiResponse?.data?.TotalCount)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                setSubAdmins([])
                setTotalCount(0)
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }

        }
        catch (error) {
            console.log(`Unable to retrive sub admins: ${error}`)
        }
    }

    const createSubAdmin = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/admin/sub-admins/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify(formData)
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    const myModal = document.querySelector('#closeModal')
                    myModal.click()
                    getSubAdmins()
                    clearFormData()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to create sub admin: ${error}`)
        }
        finally {
            setSubmitting(false)
        }

    }

    const updateSubAdmin = async () => {
        try {

            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/admin/update-sub-admin`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                body: JSON.stringify(formData)
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    const myModal = document.querySelector('#closeModal')
                    myModal.click()
                    getSubAdmins()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to update sub admin: ${error}`)
        }
        finally {
            setSubmitting(false)
        }

    }

    const handleDelete = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/owner-delete/${subAdmins[arrayKey]?._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: auth?.token,
                },
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    getSubAdmins()
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to delete owner: ${error}`)
        }
    }

    const handleCancelDelete = () => {
    }

    const finishSubmit = async () => {

        if (action == 'save') {
            createSubAdmin()
        }
        else {
            updateSubAdmin()
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
    }

    const handlePermissionCheck = (e) => {
        const { name, checked } = e.target

        if (checked) {
            permissions = [...permissions, name]
        } else {
            permissions = permissions.filter(value => value !== name)
            // Ensure at least one checkbox remains checked
            if (permissions.length == 0) {
                // Don't allow to uncheck if it's the last one checked
                return
            }
        }

        if (name == 'settings') {
            if (checked) {
                document.querySelector('input[name=\'subAdmins\']').removeAttribute('disabled')
                document.querySelector('input[name=\'categories\']').removeAttribute('disabled')
            }
            else {
                document.querySelector('input[name=\'subAdmins\']').setAttribute('disabled', true)
                document.querySelector('input[name=\'categories\']').setAttribute('disabled', true)
                let stringsToRemove = ['subAdmins', 'categories']
                permissions = permissions.filter(item => !stringsToRemove.includes(item))
            }
        }

        setPermissions(permissions)

        setFormData((prevData) => ({
            ...prevData, userPermission: permissions.join('|')
        }))
    }

    const validateValues = (formData) => {
        let forNameRegEx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/
        let forMobileRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        let forEmailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let errorMessages = {}

        if (formData.name == '') {
            errorMessages.name = 'Name required'
        }
        else if (!formData.name.match(forNameRegEx)) {
            errorMessages.name = 'Enter valid name'
        }

        if (formData.country == '' || formData.country == '0') {
            errorMessages.country = 'Choose country'
        }

        if (formData.mobile == '') {
            errorMessages.mobile = 'Mobile required'
        }
        else if (forMobileRegEx.test(formData.mobile) == false) {
            errorMessages.mobile = 'Enter valid mobile'
        }

        if (formData.email == '') {
            errorMessages.email = 'Email required'
        }
        else if (!formData.email.match(forEmailRegEx)) {
            errorMessages.email = 'Enter valid email'
        }

        if (action == 'save') {
            if (formData.password == '') {
                errorMessages.password = 'Password required'
            }
            else if (formData.password.length < 6) {
                errorMessages.password = 'Password must be 6 characters'
            }
            else if (formData.password !== formData.confirmPassword) {
                errorMessages.confirmPassword = 'Password doesn\'t match'
            }
        }

        return errorMessages
    }

    useEffect(() => {
        getSubAdmins()

        if (countries.length == 0) {
            fetchIpInfo()
        }

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

        return () => controller.abort()

    }, [errorMessages, currentPage, searchKeyword])

    return (
        <Layout>
            <AdminMenu userPermissions={auth?.user?.userPermission} />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <div className='row g-3 mb-4 align-items-center justify-content-between'>
                            <div className='col-auto'>
                                <h1 className='app-page-title mb-0'>Sub-admins</h1>
                            </div>
                            <div className='col-auto'>
                                <div className='page-utilities'>
                                    <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                        <div className='col-auto'>
                                            <form className='docs-search-form row gx-1 align-items-center'>
                                                <div className='col-auto'>
                                                    <input type='text' name='searchKeyword' className='form-control search-docs' placeholder='Search' value={searchKeyword} onChange={(e) => {
                                                        setSearchKeyword(e.target.value)
                                                        setCurrentPage(1)
                                                    }} />
                                                </div>
                                            </form>

                                        </div>
                                        <div className='col-auto'>
                                            <button type='button' className='btn app-btn-primary' data-bs-toggle='modal' data-bs-target='#staticBackdrop' onClick={() => {
                                                setAction('save')
                                            }}>
                                                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-plus-lg' viewBox='0 0 16 16'>
                                                    <path fillRule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2' />
                                                </svg>&nbsp;Create Sub-Admin
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='app-card app-card-orders-table shadow-sm mb-5'>
                            <div className='app-card-body'>
                                <div className='table-responsive'>
                                    <table className='table app-table-hover mb-0 text-left'>
                                        <thead>
                                            <tr>
                                                <th className='cell'>Name</th>
                                                <th className='cell'>Email</th>
                                                <th className='cell'>Mobile</th>
                                                <th className='cell'>Created On</th>
                                                <th className='cell'>Updated On</th>
                                                <th className='cell'>Active</th>
                                                <th className='cell'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {subAdmins?.map((subAdmin, index) =>
                                            (
                                                <tr key={subAdmin?._id}>
                                                    <td className='cell'><span className='truncate'>{subAdmin?.Name}</span></td>
                                                    <td className='cell'><span className='truncate'>{subAdmin?.Email}</span></td>
                                                    <td className='cell'><span className='truncate'>{subAdmin?.Mobile}</span></td>
                                                    <td className='cell'><span className='cell-data'>{subAdmin?.CreatedAt}</span></td>
                                                    <td className='cell'><span className='cell-data'>{subAdmin?.UpdatedAt}</span></td>
                                                    <td className='cell'>
                                                        <div className='form-check form-switch'>
                                                            <input className='form-check-input' type='checkbox' role='switch' id={`subAdminActiveCheckbox${index}`} defaultChecked={subAdmin?.Active} onChange={() => {
                                                                handleStatusClick(index)
                                                            }} />
                                                            <input type='hidden' data-bs-toggle='modal' data-bs-target='#confirmDialog' id='confirmDialogButton' />
                                                        </div>
                                                    </td>
                                                    <td className='cell'>
                                                        <Link className='text-decoration-none p-1' to='' title='Update' data-bs-toggle='modal' data-bs-target='#staticBackdrop' onClick={() => {
                                                            setPermissions(subAdmin?.UserPermission?.split('|'))
                                                            setFormData({
                                                                name: subAdmin?.Name,
                                                                mobile: subAdmin?.Mobile.split('-')[1],
                                                                email: subAdmin?.Email,
                                                                country: subAdmin?.Country,
                                                                userPermission: subAdmin?.UserPermission,
                                                                id: subAdmin?._id
                                                            })
                                                            setAction('update')
                                                        }} >
                                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-pencil-square' viewBox='0 0 16 16'>
                                                                <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                                                                <path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
                                                            </svg>
                                                        </Link>
                                                        <Link className='text-decoration-none p-1' to='' title='Delete' data-bs-toggle='modal' data-bs-target='#confirmDeleteDialog' onClick={
                                                            () => { setArrayKey(index) }
                                                        }>
                                                            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-trash text-danger' viewBox='0 0 16 16'>
                                                                <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z' />
                                                                <path d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z' />
                                                            </svg>

                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                            {
                                                subAdmins.length <= 0 && <tr key={'a'}>
                                                    <td className='cell' colSpan={7}>No data available</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                        <nav className='app-pagination'>
                            <ul className='pagination justify-content-end'>
                                <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}>
                                    <a className='page-link' href='#' tabIndex='-1' aria-disabled='true' onClick={() => handlePageChange(currentPage - 1)}>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left' viewBox='0 0 16 16'>
                                            <path fillRule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8' />
                                        </svg>&nbsp;Previous</a>
                                </li>
                                <li className={`page-item ${currentPage * pageSize >= totalCount ? 'disabled' : ''}`}>
                                    <a className='page-link' href='#' onClick={() => handlePageChange(currentPage + 1)}>Next&nbsp;
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                                            <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8' />
                                        </svg></a>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>

            <div className='modal fade' id='staticBackdrop' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                <div className='modal-dialog modal-fullscreen'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h1 className='modal-title fs-5' id='staticBackdropLabel'>Sub-admin</h1>
                            <button type='button' id='closeModal' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                        </div>
                        <div className='modal-body'>
                            <div className='auth-form-container text-start col-md-3 mx-auto'>
                                <form className='auth-form auth-signup-form'>
                                    <div className='email mb-3'>
                                        <label className='sr-only'>Name</label>
                                        <input type='text' name='name' value={formData.name} className='form-control' placeholder='Full name' onChange={handleInputChange} />
                                        {errorMessages.name ? (<p className='form-text text-danger m-0'> {errorMessages.name}</p>) : null}
                                    </div>
                                    <div className='row'>

                                        <div className='col-md-5 mb-3'>
                                            <label className='sr-only'>Country</label>
                                            <select name='country' className='form-select form-control w-100' value={formData.country} onChange={handleInputChange}>
                                                <option value='0'>Choose...</option>
                                                {countries?.map(country => (
                                                    <option key={country.id} value={country.iso2}>{country.phone_code} ({country.iso2})</option>
                                                ))}
                                            </select>
                                            {errorMessages.country ? (<p className='form-text text-danger m-0'> {errorMessages.country}</p>) : null}
                                        </div>

                                        <div className='col-md-7 mb-3'>
                                            <label className='sr-only'>Mobile No.</label>
                                            <input type='text' name='mobile' maxLength={10} value={formData.mobile} className='form-control' placeholder='Mobile number' onChange={handleInputChange} />
                                            {errorMessages.mobile ? (<p className='form-text text-danger m-0'> {errorMessages.mobile}</p>) : null}
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <label className='sr-only'>Email</label>
                                        <input type='' name='email' value={formData.email} className='form-control' placeholder='Email address' onChange={handleInputChange} />

                                        {errorMessages.email ? (<p className='form-text text-danger m-0'> {errorMessages.email}</p>) : null}
                                    </div>
                                    {action == 'save' ? (
                                        <>

                                            <div className='password mb-3'>
                                                <label className='sr-only'>Password</label>
                                                <div className='input-group'>
                                                    <input type={`${showPassword ? 'text' : 'password'}`} name='password' value={formData.password} className='form-control' onChange={handleInputChange} placeholder='Create a password' />
                                                    <button className='input-group-text' type='button' onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                            <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                            <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                        </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                            <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                            <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                            <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                        </svg>}
                                                    </button>
                                                </div>
                                                {errorMessages.password ? (<p className='form-text text-danger m-0'> {errorMessages.password}</p>) : null}
                                            </div>

                                            <div className='password mb-3'>
                                                <label className='sr-only'>Confirm Password</label>
                                                <div className='input-group'>
                                                    <input type={`${showConfirmPassword ? 'text' : 'password'}`} name='confirmPassword' value={formData.confirmPassword} className='form-control form-control' onChange={handleInputChange} placeholder='Re-enter password' />
                                                    <button className='input-group-text' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                        {showConfirmPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                            <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                            <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                        </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                            <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                            <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                            <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                        </svg>}
                                                    </button>
                                                </div>
                                                {errorMessages.confirmPassword ? (<p className='form-text text-danger m-0'> {errorMessages.confirmPassword}</p>) : null}
                                            </div>
                                        </>

                                    ) : ('')}

                                    <div className=' mb-3'>
                                        <label className='sr-only'>Permissions</label>

                                        <div className='form-check mb-3'>
                                            <ul className='list-unstyled'>
                                                <li className='list-group-item'>
                                                    <input className='form-check-input' type='checkbox' name={'qrs'} checked={permissions.includes('qrs')} onChange={handlePermissionCheck} />
                                                    <label className='form-check-label fw-semibold'>
                                                        Generate QR
                                                    </label>

                                                </li>

                                                <li className='list-group-item'>
                                                    <input className='form-check-input' type='checkbox' name={'tagged-qrs'} checked={permissions.includes('tagged-qrs')} onChange={handlePermissionCheck} />
                                                    <label className='form-check-label fw-semibold'>
                                                        Activated QRs
                                                    </label>
                                                </li>

                                                <li className='list-group-item'>
                                                    <input className='form-check-input' type='checkbox' name={'owners'} checked={permissions.includes('owners')} onChange={handlePermissionCheck} />
                                                    <label className='form-check-label fw-semibold'>
                                                        Owners
                                                    </label>
                                                </li>

                                                <li className='list-group-item'>
                                                    <input className='form-check-input' type='checkbox' name={'settings'} checked={permissions.includes('settings')} onChange={handlePermissionCheck} />
                                                    <label className='form-check-label fw-semibold'>
                                                        Settings
                                                    </label>
                                                    <ul>
                                                        <li className='list-group-item'>
                                                            <input className='form-check-input' type='checkbox' name={'subAdmins'} checked={permissions.includes('subAdmins')} onChange={handlePermissionCheck} />
                                                            <label className='form-check-label'>
                                                                Sub-Admins
                                                            </label>
                                                        </li>

                                                        <li className='list-group-item'>
                                                            <input className='form-check-input' type='checkbox' name={'categories'} checked={permissions.includes('categories')} onChange={handlePermissionCheck} />
                                                            <label className='form-check-label'>
                                                                Categories
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <button type='submit' onClick={handleSubmit} className='btn app-btn-primary w-100 theme-btn mx-auto' disabled={submitting}>Submit</button>
                                    </div>
                                    {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal fade' id='confirmDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to deactivate?`}
                        onConfirm={handleStatus}
                        onCancel={handleCancelDeactivate}
                        arrayKey={arrayKey}
                    />
                )}
            </div>

            <div className='modal fade' id='confirmDeleteDialog' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                {(
                    <ConfirmationDialog
                        message={`Are you sure you want to delete?`}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        arrayKey={arrayKey}
                    />
                )}
            </div>

        </Layout>
    )
}

export default SubAdmin