import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layouts/Layout'
import OwnerMenu from '../../components/Layouts/OwnerMenu'
import { useAuth } from '../../context/auth'


const CallLogs = () => {

    const [auth, setAuth] = useAuth()
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const [timeFilter, setTimeFilter] = useState('all')
    const [searchKeyword, setSearchKeyword] = useState('')
    const [callLogs, setCallLogs] = useState([])
    const [errorMessages, setErrorMessages] = useState({})

    const controller = new AbortController()
    const signal = controller.signal

    const getCallLogs = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/owner/call-logs?page=${currentPage}&limit=${pageSize}&search=${searchKeyword}&time=${timeFilter}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: auth?.token
                    },
                    signal
                })

            if (response.ok) {
                const apiResponse = await response.json()

                if (apiResponse?.success) {
                    setCallLogs(apiResponse?.data?.CallLogs)
                    setTotalCount(apiResponse?.data?.TotalCount)
                }
                else {
                    errorMessages.response = apiResponse?.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                setCallLogs([])
                setTotalCount(0)
                throw new Error(`Network response was not ok, status:${response.status}, message:${response.statusText}`)
            }

        } catch (error) {
            console.log(`Unable to fetch tagged QRs: ${error}`)
        }
    }

    useEffect(() => {
        getCallLogs()
        return () => controller.abort()
    }, [currentPage, searchKeyword, timeFilter])

    return (
        <Layout>
            <OwnerMenu />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <div className='row g-3 mb-4 align-items-center justify-content-between'>
                            <div className='col-auto'>
                                <h1 className='app-page-title mb-0'>Call Logs</h1>
                            </div>

                            <div className='col-auto'>
                                <div className='page-utilities'>
                                    <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                        <div className='col-auto'>
                                            <form className='table-search-form row gx-1 align-items-center'>
                                                <div className='col-auto'>
                                                    <input type='text' name='searchKeyword' className='form-control search-orders' placeholder='Search' value={searchKeyword} onChange={(e) => {
                                                        setSearchKeyword(e.target.value)
                                                        setCurrentPage(1)
                                                    }} />
                                                </div>
                                            </form>

                                        </div>
                                        <div className='col-auto'>

                                            <select value={timeFilter} className='form-select w-auto' onChange={(e) => {
                                                setTimeFilter(e.target.value)
                                            }} >
                                                <option value='all'>All</option>
                                                <option value='thisWeek'>This week</option>
                                                <option value='thisMonth'>This month</option>
                                                <option value='lastThreeMonths'>Last 3 months</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='app-card app-card-account shadow-sm d-flex flex-column align-items-start'>
                                <div className='app-card-body px-4 w-100'>
                                    {callLogs?.map((call) => (
                                        <div key={call?._id} className='item border-bottom py-3'>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-auto'>
                                                    <div className='item-label'><strong>{call?.UserName}</strong></div>
                                                    <div className='item-label'>{call?.UserId}</div>
                                                    <div className='item-data'>{call?.CallType}</div>
                                                    <div className='item-data'>{call?.CreatedAt}</div>
                                                </div>
                                                <div className='col text-end'>
                                                    <div className='item-data'>{call?.Reason}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CallLogs