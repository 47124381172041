import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ResetPassword = () => {

    const [errorMessages, setErrorMessages] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [formData, setFormData] = useState({
        token: '',
        password: '',
        confirmPassword: ''
    })

    const controller = new AbortController()
    const signal = controller.signal

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorMessages(validateValues(formData))
        setSubmitting(true)
    }

    const validateValues = (data) => {

        let errorMessages = {}

        if (data.password == '') {
            errorMessages.password = 'Password required'
        }
        else if (data.password.length < 6) {
            errorMessages.password = 'Password must be 6 characters'
        }
        else if (data.password !== data.confirmPassword) {
            errorMessages.confirmPassword = 'Password doesn\'t match'
        }


        return errorMessages
    }

    const finishSubmit = async () => {
        try {
            let errorMessages = {}

            const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/auth/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                signal
            })

            if (response.ok) {
                const apiResponse = await response.json()
                if (apiResponse.success) {
                    navigate('/login')
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
                else {
                    errorMessages.response = apiResponse.message
                    setErrorMessages(errorMessages)
                }
            }
            else {
                throw new Error(`Network response was not ok,status:${response.status}, message:${response.statusText}`)
            }
        }
        catch (error) {
            console.log(`Unable to create account: ${error}`)
        }
        finally {
            setSubmitting(false)
        }
    }

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const params = {};
        for (const [key, value] of urlParams) {
            params[key] = value;
        }

        setFormData((prevData) => ({
            ...prevData, token: params?.token
        }))

        if (Object.keys(errorMessages).length == 0 && submitting) {
            finishSubmit()
        }
        else {
            setSubmitting(false)
        }

        return () => controller.abort()
        //eslint-disable-next-line
    }, [errorMessages])

    return (
        <>
            <div className='app-404-page'>
                <div className='container mb-5'>
                    <div className='row'>
                        <div className='col-12 col-md-11 col-lg-7 col-xl-6 mx-auto'>
                            <div className='app-branding text-center mb-5'>
                                <div className='app-logo'>
                                    <img className='logo-icon me-2' src={`${process.env.PUBLIC_URL}/Images/Logo/ScanConnectLogoSm.svg`} alt='ScanConnect Logo' />
                                </div>
                            </div>
                            <div className='app-card p-2 text-center shadow-sm'>
                                <h1 className='page-title mb-4'>
                                    <span className='font-weight-light'>Reset Password</span>
                                </h1>
                                <div className='text-start mx-auto w-75'>
                                    <form>
                                        <div className='password mb-3'>
                                            <label className='sr-only'>Password</label>
                                            <div className='input-group'>
                                                <input type={`${showPassword ? 'text' : 'password'}`} name='password' value={formData.password} className='form-control' onChange={handleInputChange} placeholder='Create a password' />
                                                <button className='input-group-text' type='button' onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                    </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                        <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                        <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                        <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                    </svg>}
                                                </button>
                                            </div>
                                            {errorMessages.password ? (<p className='form-text text-danger m-0'> {errorMessages.password}</p>) : null}
                                            <div className='form-text'>
                                                Your password at least 6 characters long, contain letters, numbers and special characters, and must not contain spaces, or emoji.
                                            </div>
                                        </div>

                                        <div className='password mb-3'>
                                            <label className='sr-only'>Confirm Password</label>
                                            <div className='input-group'>
                                                <input type={`${showConfirmPassword ? 'text' : 'password'}`} name='confirmPassword' value={formData.confirmPassword} className='form-control form-control' onChange={handleInputChange} placeholder='Re-enter password' />
                                                <button className='input-group-text' type='button' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {showConfirmPassword ? <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye' viewBox='0 0 16 16'>
                                                        <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
                                                        <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
                                                    </svg> : <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-eye-slash' viewBox='0 0 16 16'>
                                                        <path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
                                                        <path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
                                                        <path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
                                                    </svg>}
                                                </button>
                                            </div>
                                            {errorMessages.confirmPassword ? (<p className='form-text text-danger m-0'> {errorMessages.confirmPassword}</p>) : null}
                                        </div>

                                        <div className='text-center'>
                                            <button type='submit' onClick={handleSubmit} className='btn app-btn-primary w-100 theme-btn mx-auto' disabled={submitting}>Reset</button>
                                        </div>
                                        {errorMessages.response ? (<p className='form-text text-danger m-0'> {errorMessages.response}</p>) : null}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='mt-auto w-100 bottom-0 start-0 py-2' style={{ background: '#edfdf6' }}>

                <div className='col-sm-12 text-center'>
                    <p className='mb-0 text-sm text-gray-600'>&copy;{new Date().getFullYear()} ScanConnect&trade;</p>
                </div>
            </footer>
        </>
    )
}

export default ResetPassword