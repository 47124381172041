import React from 'react'
import Layout from '../../components/Layouts/Layout'
import OwnerMenu from '../../components/Layouts/OwnerMenu'

const Dashboard = () => {
    return (
        <Layout>
            <OwnerMenu />
            <div className='app-wrapper-admin'>
                <div className='app-content pt-3 p-md-3 p-lg-4'>
                    <div className='container-xl'>
                        <h2>Owner Dashboard</h2>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard