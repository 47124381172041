import React from 'react'

const Loader = () => {
    return (
        <>
            {/* <div className='d-flex flex-column justify-content-center align-items-center vh-100'> */}
                <div className='text-center'>
                    <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default Loader