import React from 'react';

const Footer = () => {
    return (

        <footer className='mt-auto w-100 bottom-0 start-0 py-2' style={{ background: '#edfdf6' }}>
            <div className='col-sm-12 text-center'>
                <p className='mb-0 text-sm text-gray-600'>&copy;{new Date().getFullYear()} ScanConnect&trade;</p>
            </div>
        </footer>
    )
}

export default Footer