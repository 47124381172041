import react from 'react'

const CategoryForm = ({submitting,handleSubmit,formData,handleInputChange,errorMessages}) => {
    return (
        <>
            <div className='modal-header'>
                <h1 className='modal-title fs-5' id='staticBackdropLabel'>Category</h1>
                <button type='button' id='closeModal' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div className='modal-body'>

                <form className='row gx-3  align-items-center'>
                    <div className='row mb-3'>
                        <label className='col-sm-3 col-form-label col-form-label-sm'>Category</label>

                        <div className='col-sm-9 '>
                            <label className='visually-hidden' >Preference</label>
                            <input type='text' className='form-control' name='name' value={formData.name} onChange={handleInputChange} />
                            {errorMessages.name ? (<p className='text text-start text-danger mb-0'> {errorMessages.name}</p>) : null}
                        </div>
                    </div>

                    <div className='row'>
                        <label className='col-sm-3 col-form-label col-form-label-sm'>Error Label</label>

                        <div className='col-sm-9'>
                            <label className='visually-hidden' >Preference</label>
                            <input type='text' className='form-control' name='errorLabel'  value={formData.errorLabel} onChange={handleInputChange} />
                            {errorMessages.errorLabel ? (<p className='text text-start text-danger mb-0'> {errorMessages.errorLabel}</p>) : null}
                        </div>
                    </div>
                </form>

            </div>
            <div className='modal-footer'>
                {errorMessages.response ? (<p className='text text-start text-danger mb-0'> {errorMessages.response}</p>) : null}
                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>Close</button>
                <button type='button' className='btn btn-primary' onClick={handleSubmit} disabled={submitting}>Save</button>
            </div>
        </>
    )
}

export default CategoryForm